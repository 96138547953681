import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { remoteValues } from '~/common/utils/firebase/remoteConfig';
import { ItemText, MenuItem } from '~/components/SideActions/SideActions.styled';

import config from '~/store/config';
import { getLoggedUser } from '~/store/user/actions';
import { getPersonDetail } from '~/store/people/actions';
import { accessByCostumer } from '~/store/accessByCustomer/actions';

type AccessByCostumerButtonProps = {
  onClick?: () => void;
};

function AccessByCostumerButton({ onClick }: AccessByCostumerButtonProps) {
  const { enableAccessByClient } = remoteValues;

  const [accessByCostumerControl, getPersonDetailControl, getLoggedUserControl] = useAwaitControl([
    accessByCostumer,
    getPersonDetail,
    getLoggedUser,
  ]);

  const REGISTRATIONS_STATUS_PERMITTED = [
    'PASSWORD',
    'COMPLETE',
    'PENDING_IN100',
    'APPROVED',
    'BACKOFFICE_PENDING',
    'AGREEMENT_PASSWORD',
    'UNDER_ANALISYS',
    'UNDER_ANALYSIS',
    'VALIDATION',
  ];

  const customerAccessSuccess = accessByCostumerControl.isSuccessful();
  const customerData = accessByCostumerControl.result();
  const userData = getLoggedUserControl.result();
  const personDetails = getPersonDetailControl.result();

  const handleClientAccess = () => {
    const personId = personDetails?.id;

    onClick?.();

    accessByCostumerControl.start({ id: personId });
  };

  useEffect(() => {
    if (customerAccessSuccess) {
      const {
        agreementId,
        agreementKey,
        enrollment,
        name,
        document: personDocument,
      } = personDetails;
      const { authenticationToken, registrationStatus } = customerData;
      const { id: agentId, name: agentName } = userData;

      const params = new URLSearchParams();

      const paramsConfig = {
        isAgent: true,
        agentId,
        agentName,
        agreementId,
        agreementKey,
        enrollment,
        name,
        personDocument,
        authenticationToken,
        registrationStatus,
      };

      Object.keys(paramsConfig).forEach((key) => {
        params.append(key, paramsConfig[key]);
      });

      window.open(`${config.webApp}?${params}`);
    }

    accessByCostumerControl.clear();
  }, [customerAccessSuccess]);

  if (
    !REGISTRATIONS_STATUS_PERMITTED.includes(personDetails.registrationStatus) ||
    personDetails?.disabled
  ) {
    return null;
  }

  if (!enableAccessByClient) {
    return null;
  }

  return (
    <MenuItem onClick={handleClientAccess}>
      <ItemText>Acessar pelo Cliente</ItemText>
    </MenuItem>
  );
}

export default AccessByCostumerButton;
