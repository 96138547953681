import styled, { css } from 'styled-components';
import { Typography } from '~/ui/components';

interface WrapperProps {
  disabled?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 320px;
  justify-content: space-between;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border: 0.5px solid ${({ theme }) => theme.colors.element.disabledBackground};
  border-radius: 8px;
  margin-bottom: 8px;

  ${({ disabled }) =>
    !disabled &&
    css`
      :hover {
        border: 1px solid ${({ theme }) => theme.colors.brand.primary};
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        cursor: pointer;
      }
    `}
`;

interface InfoProps {
  bold?: boolean;
}

export const Info = styled<InfoProps>(Typography).attrs(({ bold }) => ({
  type: 'description',
  element: 'div',
  weight: bold ? 500 : 400,
}))`
  color: ${({ theme }) => theme.colors.element.secondary};
  font-size: ${({ bold }) => (bold ? 14 : 12)}px;
`;

interface ContentProps {
  row?: boolean;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  gap: 4px;

  ${({ row }) =>
    row &&
    css`
      justify-content: space-between;
      align-items: start;
    `}
`;

export const Label = styled(Typography).attrs({
  type: 'paragraphSmall',
  weight: 400,
})`
  color: ${({ theme }) => theme.colors.element.secondary};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
