import React, { Component } from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import config from '~/store/config';
import { isUndefined, mapObject } from 'underscore';

import message from '~/common/message';

import { OldActions, Spinner } from '~/components';
import { getLoggedUser } from '~/store/user/actions';
import { getPersonDetail } from '~/store/people/actions';
import { accessByCostumer } from '~/store/accessByCustomer/actions';
import { remoteValues } from '../../../common/utils/firebase/remoteConfig';

const REGISTRATIONS_STATUS_PERMITTED = [
  'PASSWORD',
  'COMPLETE',
  'PENDING_IN100',
  'APPROVED',
  'BACKOFFICE_PENDING',
  'AGREEMENT_PASSWORD',
  'UNDER_ANALISYS',
  'UNDER_ANALYSIS',
  'VALIDATION',
];

class AcessByCustomer extends Component {
  componentDidUpdate() {
    (() => {
      const {
        accessByCustomer,
        accessByCustomerIsLoading,
        accessFailed,
        accessByCustomerIsSuccessful,
      } = this.props;

      if (accessByCustomerIsSuccessful) {
        const {
          agreementId,
          agreementKey,
          enrollment,
          name,
          document: personDocument,
        } = this.props.personDetails;

        const { authenticationToken, registrationStatus } = this.props.accessByCustomer;

        const { id: agentId, name: agentName } = this.props.loggedUser;

        const params = new URLSearchParams();

        mapObject(
          {
            isAgent: true,
            agentId,
            agentName,
            agreementId,
            agreementKey,
            enrollment,
            name,
            personDocument,
            authenticationToken,
            registrationStatus,
          },
          (value, key) => {
            if (isUndefined(value) || value === '' || (Array.isArray(value) && !value.length)) {
              return params.delete(key);
            }
            return params.set(key, value);
          },
        );

        window.open(`${config.webApp}?${params}`);
      }

      if (accessFailed) {
        message.error('Falha ao tentar gerar acesso.');
      }
    })();
  }

  onPressAccessByCustomer = () => {
    const customerId = this.props.customerId || this.props.match.params.personId;
    this.props.dispatch(accessByCostumer.start({ id: customerId }));
  };

  render() {
    const loading = this.props.accessByCustomerIsLoading;
    const { registrationStatus } = this.props.personDetails;
    const { enableAccessByClient } = remoteValues;

    if (!REGISTRATIONS_STATUS_PERMITTED.includes(registrationStatus)) {
      return null;
    }

    if (!enableAccessByClient) {
      return null;
    }

    return [
      <Spinner spinning={loading} />,
      <OldActions
        key="access-by-customer"
        title="Acessar pelo Cliente"
        onPress={this.onPressAccessByCustomer}
      />,
    ];
  }
}

function mapStateToProps(state) {
  return {
    accessByCustomer: accessByCostumer.getResult()(state),
    accessByCustomerIsLoading: accessByCostumer.isRunning()(state),
    accessByCustomerIsSuccessful: accessByCostumer.isSuccessful()(state),
    accessFailed: accessByCostumer.hasFailure()(state),
    personDetails: getPersonDetail.getResult()(state),
    loggedUser: getLoggedUser.getResult()(state),
  };
}

export default compose(withRouter, connect(mapStateToProps))(AcessByCustomer);
