import React from 'react';

import { Typography } from '~/ui/components';
import { CaretRight, Document } from '~/ui/assets/icons';
import { Card as Box, Info, Section } from '../../Integration.styled';

type CardType = {
  id: number;
  title: string;
  description: string;
  disabled?: boolean;
  onClick: () => void;
};

export type CardProps = {
  card: CardType;
};

export function Card({ card }: CardProps) {
  return (
    <Box disabled={card.disabled} onClick={card.onClick}>
      <Info>
        <Document width={24} height={24} />
        <Section gap={4} maxWidth={320}>
          <Typography type="paragraphSmall" weight={600}>
            {card.title}
          </Typography>
          <Typography type="paragraphSmall">{card.description}</Typography>
        </Section>
      </Info>
      <CaretRight />
    </Box>
  );
}
