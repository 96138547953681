import React from 'react';
import { Shimmer } from '~/ui/components';

import { Content, Wrapper, Section } from './CardShimmer.styled';

export function CardShimmer() {
  return (
    <Wrapper>
      <Section>
        <Content row>
          <Content width={100}>
            <Shimmer br={8} width={70} height={16} />
            <Shimmer br={8} width={100} height={18} />
          </Content>
          <Shimmer br={8} width={110} height={20} />
        </Content>
        <Shimmer br={8} width={200} height={28} />
      </Section>
      <Section>
        <Content>
          <Shimmer br={8} width={80} height={24} />
          <Shimmer br={8} width={175} height={36} />
        </Content>
        <Shimmer br={16} width={270} height={36} />
      </Section>
    </Wrapper>
  );
}
