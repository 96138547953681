import React from 'react';

import { Shimmer } from '~/ui/components';
import { Content, Wrapper } from './History.styled';

export function HistoryLoading() {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <Wrapper key={index}>
          <Content>
            <Shimmer br={8} width={180} height={20} />
            <Shimmer br={8} width={130} height={18} />
          </Content>
          <Shimmer br={8} width={24} height={24} />
        </Wrapper>
      ))}
    </>
  );
}
