import { useCallback, useEffect } from 'react';
import { ampli, PromiseResult, Result } from '~/ampli';
import { getLoggedUser } from '~/store/user/actions';
import useAsyncActionStatus from './useAsyncActionStatus';

export const useTracking = () => {
  const loggedUserName = useAsyncActionStatus(getLoggedUser)?.result?.name;

  const updateUserId = (value: string) => {
    const name = value.toLocaleLowerCase().replace(/\s+/g, '_');
    ampli.identify(name);
  };

  const handleTracking = useCallback((trackEventFunction: () => PromiseResult<Result>) => {
    try {
      trackEventFunction();
      const response = ampli.flush();

      return response;
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (loggedUserName) {
      updateUserId(loggedUserName.toString());
    }
  }, [loggedUserName]);

  return { handleTracking };
};
