enum InsuranceStatus {
  'BILLING_CONFIRMED' = 'Em aprovação',
  'PENDING_PROPOSAL_PRE_IMPLEMENTATION' = 'Em aprovação',
  'PROPOSAL_PRE_IMPLEMENTED' = 'Em aprovação',
  'PENDING_PROPOSAL_PDF_GENERATION' = 'Em aprovação',
  'PENDING_PROPOSAL_NUMBER_GENERATION' = 'Em aprovação',
  'PROPOSAL_PDF_GENERATED' = 'Em aprovação',
  'PENDING_SIGNATURE_COMMUNICATION' = 'Em aprovação',
  'SIGNATURE_COMMUNICATED' = 'Em aprovação',
  'PENDING_PROPOSAL_PDF_INDEXING' = 'Em aprovação',
  'PROPOSAL_PDF_INDEXED' = 'Em aprovação',
  'PENDING_PROPOSAL_STATUS' = 'Em aprovação',
  'PROPOSAL_STATUS_RETRIEVED' = 'Em aprovação',
  'WAITING_ICATU_ACCEPTANCE' = 'Em aprovação',
  'ICATU_ACCEPTED' = 'Aprovado',
  'CANCEL_DUE_TO_DELINQUENCY' = 'Cancelado',
  'CANCEL_MADE' = 'Cancelado',
  'ICATU_CANCELED' = 'Cancelado',
  'PROPOSAL_CANCELED' = 'Cancelado',
  'PAYMENT_FAILED' = 'Cancelado',
  'PROPOSAL_REJECTED' = 'Cancelado',
}

export enum InsuranceStatusColor {
  'Em aprovação' = 'alert',
  'Aprovado' = 'positive',
  'Cancelado' = 'negative',
}

export default InsuranceStatus;
