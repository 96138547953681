// @flow
import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';

import useDimensions from '~/hooks/useDimensions';
import Timer from '~/screens/DistributedContracts/Timer';
import Table from '~/components/Table';
import OperationStepType from '~/typings/enums/OperationStepType';
import { CONTRACT_QUEUE_STATUS } from '~/typings/enums/ContractQueueStatus';
import LoanType, { LOAN_TYPES } from '~/typings/enums/LoanType';
import toDate from '~/common/masked/toDate';
import { QueuedContract } from '~/typings/entities/contract';
import { PROFILE_FILTER_NAMES } from '~/screens/DistributedContracts/components/ProfileConfig/ProfileConfig';
import { ampli } from '~/ampli';
import { useTracking } from '~/hooks/useTracking';

type TableProps = {
  contracts: QueuedContract[];
};

const { FGTS } = CONTRACT_QUEUE_STATUS;

const CARD_PRODUCTS = [LOAN_TYPES.CONSIGNED_CARD_RMC, LOAN_TYPES.BENEFIT_CARD_RCC];

export function ContractTable({ contracts }: TableProps) {
  const containerRef = useRef(null);
  const { width } = useDimensions(containerRef.current);
  const { handleTracking } = useTracking();

  const handleTrackEvent = () => {
    const trackEvent = ampli.conferenciaIniciouConferencia({
      titulo_tela: 'Conferência de contratos',
    });

    handleTracking(() => trackEvent);
  };

  const renderLink = useCallback((row) => {
    const { contractId, operationStepType } = row.original;

    const ALLOWED_NEW_PRODUCTS = [...CARD_PRODUCTS, LOAN_TYPES.PIX_CREDIT_CARD];

    if (!contractId) return <div className="text-center">-</div>;

    if (ALLOWED_NEW_PRODUCTS.includes(row.original.loanType)) {
      return (
        <Link onClick={handleTrackEvent} to={`/backoffice/contract/${contractId}`}>
          {contractId}
        </Link>
      );
    }

    return (
      <div className="text-center">
        <Link
          onClick={handleTrackEvent}
          href="/"
          target="_blank"
          to={`/backoffice/contracts/${contractId}?panel=${FGTS === operationStepType ? 3 : 1}`}
        >
          {contractId}
        </Link>
      </div>
    );
  }, []);

  const { current: presentation } = useRef({
    columns: [
      {
        name: 'COD',
        props: {
          width: width * 0.1,
          Cell: renderLink,
        },
      },
      {
        name: 'DATA',
        props: {
          width: width * 0.1,
          Cell: (row) => <div className="text-center">{toDate(row.original.contractDate)}</div>,
        },
      },
      {
        name: 'PRODUTO',
        props: {
          width: width * 0.1,
          Cell: (row) => <div className="text-center">{LoanType(row.original.loanType)}</div>,
        },
      },
      {
        name: 'CLIENTE',
        accessor: 'personName',
        props: {
          width: width * 0.25,
          Cell: (row) => <div className="text-center">{row.original.personName}</div>,
        },
      },
      {
        name: 'STEP',
        props: {
          Cell: (row) => (
            <div className="text-center">{OperationStepType(row.original.operationStepType)}</div>
          ),
        },
      },
      {
        name: 'PERFIL',
        props: {
          width: width * 0.1,
          Cell: (row) => (
            <div className="text-center">
              {PROFILE_FILTER_NAMES?.[row.original.kycStatus] || '-'}
            </div>
          ),
        },
      },
      {
        name: 'TEMPO PARA CONFERIR',
        props: {
          width: width * 0.15,
          Cell: (row) => <Timer endTime={row.original.endDate} />,
        },
      },
    ],
  });

  return <Table ref={containerRef} presentation={presentation} items={contracts} hidePagination />;
}
