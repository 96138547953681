import React from 'react';
import { InfoLabel, InfoValue, Wrapper } from './InfoGroup.styled';
import Shimmer from '../Shimmer/Shimmer';

interface InfoGroupProps {
  label: string;
  value: string | number | React.ReactElement;
  valueType?: string;
  valueWeight?: number;
  valueColor?: string;
  copyText?: string;
  isLoading?: boolean;
}

export function InfoGroup({
  label,
  value,
  valueType,
  valueWeight,
  valueColor,
  copyText,
  isLoading = false,
}: InfoGroupProps) {
  const isReactElement = React.isValidElement(value);

  return (
    <Wrapper>
      <InfoLabel>{label}</InfoLabel>
      {isLoading && <Shimmer br={8} width={85} height={20} />}
      {isReactElement && !isLoading && value}
      {!isReactElement && !isLoading && (
        <InfoValue
          type={valueType}
          weight={valueWeight}
          color={valueColor}
          copyText={copyText || value}
          copyable={!!copyText || !!value}
        >
          {value ?? '-'}
        </InfoValue>
      )}
    </Wrapper>
  );
}
