const CONTRACT_STATUS = {
  INITIAL: 'Simulação Iniciada',
  REQUESTED: 'Requerido',
  SIGNED: 'Assinado',
  PAID: 'Pago',
  PORTABILITY_PAID: 'Portabilidade Paga',
  CCBSIGNED: 'CCB Assinada',
  CANCELLED: 'Cancelado',
  CANCELED: 'Cancelado',
  CANCELLED_BY_SYSTEM: 'Cancelado Automaticamente',
  CANCELLED_BY_USER: 'Cancelado pelo Usuário',
  PROCESSED: 'Enviado',
  ENDORSEMENT: 'Averbação',
  ENDORSED: 'Averbado',
  GENERATED: 'Gerado',
  ACTIVE: 'Ativo',
  BLOCKED: 'Bloqueado',
};

export const CONTRACT_STATUS_COLOR = {
  SIGNED: 'info',
  PAID: 'positive',
  CANCELLED: 'negative',
  CANCELED: 'negative',
  INITIAL: 'alert',
  CANCELLED_BY_SYSTEM: 'negative',
  CANCELLED_BY_USER: 'negative',
  PORTABILITY_PAID: 'positive',
  REQUESTED: 'neutral',
  CCBSIGNED: 'neutral',
  FINISHED: 'positive',
  CANCELLATION_REQUESTED: 'negative',
  PROCESSED: 'positive',
  ENDORSEMENT: 'info',
  ENDORSED: 'info',
  GENERATED: 'positive',
  ACTIVE: 'positive',
  BLOCKED: 'negative',
};

export const INSURANCE_STATUS_COLOR = {
  Ativo: 'positive',
  'Cancelamento em andamento': 'negative',
  Cancelado: 'negative',
  'Em análise': 'alert',
  Encerrado: 'neutral',
  'Em aprovação': 'alert',
  Aprovado: 'positive',
};

function ContractStatus(props: any) {
  if (props) {
    return CONTRACT_STATUS[props.value] || CONTRACT_STATUS[props] || null;
  }
  return null;
}

export default ContractStatus;
