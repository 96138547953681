import styled, { css } from 'styled-components';
import { themeColor } from '~/common/utils/theme';
import { Button } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 24px 24px 24px;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow: overlay;

  &::-webkit-scrollbar {
    height: 6px;
  }
`;

interface ContentSectionProps {
  pointer?: boolean;
}

export const ContentSection = styled.div<ContentSectionProps>`
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.element.disabled};

    :hover {
      color: ${({ theme }) => theme.colors.brand.primary};
    }
  }

  svg:first-child {
    transform: rotate(180deg);
  }

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 13px;
  height: 150px;
`;

export const Title = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

interface CollapseProps {
  collapsed?: boolean;
}

export const CollapseButton = styled<CollapseProps>(Button).attrs(({ collapsed }) => ({
  size: 'sm',
  iconOnly: true,
  variant: 'text',
  icon: 'CaretUp',
  title: collapsed ? 'Expandir' : 'Recolher',
  customColor: '#1a1a1a',
}))`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.background.primary};
  border-radius: 8px;
  margin-left: -41px;
  margin-right: 2px;
  z-index: 99;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  svg {
    color: ${themeColor('brand.primary')};
    width: 12px;
    height: 12px;
    transition: transform 0.24s, -webkit-transform 0.24s;
    transform: rotate(180deg);
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      svg {
        transform: rotate(90deg) !important;
      }
    `}

  &:disabled {
    opacity: 1;

    svg {
      opacity: 0.4;
    }
  }
`;
