import styled from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.brand.primary};
    cursor: pointer;
  }

  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.element.disabledBackground};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: -webkit-fill-available;
`;

export const Title = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Info = styled(Typography).attrs(({ theme }) => ({
  type: 'bodyXSmall',
  color: themeToggleColor(theme, 'element.secondary'),
  element: 'p',
}))``;
