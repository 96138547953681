import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAwaitControl } from 'react-redux-await-control';
import queryString from 'query-string';
import _ from 'lodash';

import screenRegister from '~/hoc/screenRegister';
import { listClixContracts } from '~/store/clix/actions';

import usePagination from '~/hooks/usePagination';
import { message } from 'antd';
import { ContractsTable } from './Tables/ContractsTable/ContractsTable';
import { Filters } from './Filters/Filters';
import { useTracking } from '~/hooks/useTracking';
import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { getLoggedUser } from '~/store/user/actions';
import { ampli } from '~/ampli';

function ContractsClix() {
  const history = useHistory();
  const clixContractsControl = useAwaitControl(listClixContracts);
  const loading = clixContractsControl.isRunning();
  const params = queryString.parse(history.location.search);
  const contractsResult = clixContractsControl.result();
  const contracts = contractsResult?.contracts;
  const pagination = contractsResult?.pagination;
  const loggedUserId = useAsyncActionStatus(getLoggedUser)?.result?.id;
  const { handleTracking } = useTracking();

  const { page, size, paginatedData, renderPagination } = usePagination({
    data: contracts,
    total: pagination?.totalElements,
    pageSize: 10,
  });

  const setQueryParams = (params) => {
    const { pathname } = history.location;
    const newSearch = queryString.stringify(params);
    history.replace({ pathname, search: newSearch });
  };

  const fetchContracts = (values) => {
    clixContractsControl.start({ ...values });
  };

  const handleOnSubmit = (values) => {
    if (_.isEmpty(values)) {
      return message.warning('É necessário preencher pelo menos um dos filtros');
    }

    if (values.warranty === 'CREDIT' && !(values.nsu || values.transactionId || values.reference)) {
      return message.warning(
        'É necessário preencher um dos filtros: NSU ou Reference ou N. da Transação.',
      );
    }

    if (values.warranty && values.warranty !== 'CREDIT' && !values.signatureDate) {
      return message.warning('É necessário preencher a data de assinatura');
    }

    setQueryParams(values);
    fetchContracts({
      page,
      size,
      ...values,
    });
  };

  useEffect(() => {
    if (!_.isEmpty(params) && !paginatedData?.length) {
      fetchContracts({
        page,
        size,
        ...params,
      });
    }
  }, [page]);

  useEffect(
    () => () => {
      clixContractsControl.clear();
    },
    [],
  );

  useEffect(() => {
    if (loggedUserId) {
      const event = ampli.visualizacaoVisualizouTela({
        titulo_tela: 'Clix',
      });

      handleTracking(() => event);
    }
  }, [loggedUserId]);

  return (
    <>
      <Filters onSubmit={handleOnSubmit} loading={loading} />
      <ContractsTable data={paginatedData} loading={loading} />
      {contracts && renderPagination()}
    </>
  );
}

export default screenRegister({
  screenName: 'ClixContractsList',
  headerTitle: 'Clix',
  path: '/backoffice/clix',
})(ContractsClix);
