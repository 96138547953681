import Masker from 'vanilla-masker';

export default function toPhone(phoneNumber) {
  if (phoneNumber) {
    const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');
    const pattern = formattedPhoneNumber.length < 11 ? '(99) 9999-9999' : '(99) 99999-9999';
    return Masker.toPattern(formattedPhoneNumber, pattern);
  }

  return null;
}
