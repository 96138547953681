/* @flow */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { Col, DatePicker, Input } from 'antd';
import { isEmpty } from 'underscore';
import masked from '~/common/masked';
import { Card as Segment, Spinner } from '~/components';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import Immutable from 'immutable';
import { getLoggedUser } from '~/store/user/actions';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { LoanHistoryTable } from './components';

import { Row, FirstRow, Button, Title, Form } from './PersonContractExtract.styled';
import RolesTypes from '../../typings/enums/RolesTypes';

const { Item: FormItem } = Form;
const { toMoneyForm } = masked;

type Props = {
  form: {
    validateFields: () => any,
  },
  getFieldDecorator: any,
  updatingMargin: Boolean,
  isResponseBackFromServer: Boolean,
  isResponseBackFromServerWithError: Boolean,
  onSubmit: () => void,
};

class PersonContractExtract extends Component<Props> {
  constructor(props) {
    super(props);

    this.financialInstitutionRef = React.createRef();
    this.state = {
      personId: this.props.match.params.id,
      marginLoan: undefined,
      marginCard: undefined,
      marginExpirationDate: undefined,
    };
  }

  // Seta os valores no formulario de margem ao acessar a tela via link ou diretamente
  componentDidUpdate(prevProps) {
    (() => {
      if (
        !Immutable.is(prevProps.details, this.props.details) ||
        !Immutable.is(prevProps.getDetailsSuccess, this.props.getDetailsSuccess)
      ) {
        if (this.props.getDetailsSuccess) {
          const personDetails = this.props.details;
          const { marginLoan, marginCard, marginExpirationDate } = personDetails;

          this.setState({ marginLoan, marginCard, marginExpirationDate }, () => {
            this.props.form.setFieldsValue({
              marginLoan: marginLoan ? marginLoan.toFixed(2) : undefined,
              marginCard: marginCard ? marginCard.toFixed(2) : undefined,
              marginExpirationDate: marginExpirationDate
                ? moment(marginExpirationDate).utc()
                : undefined,
            });
          });
        }
      }
    })();
  }

  stripMoneyValue = (value) =>
    // Divide por 100 devido a retirada da virgula
    value.replace(/[.,\s]/g, '').replace(/^0+/, '') / 100;

  stripNominalRateValue = (value) =>
    // Alem do tratamento da virgula, o backend precisa do valor dividido por 100
    this.stripMoneyValue(value) / 100;

  onMarginLoanChange = (e) => {
    this.setState({ marginLoan: this.stripMoneyValue(e.target.value) });
  };

  onMarginCardChange = (e) => {
    this.setState({ marginCard: this.stripMoneyValue(e.target.value) });
  };

  onExpirationDateChange = (marginExpirationDate) => {
    this.setState({ marginExpirationDate });
  };

  updateMargin = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err || isEmpty(err)) {
        this.props.onSubmit(this.state);
      }
    });
  };

  renderTable = (getFieldDecorator) => (
    <div>
      <LoanHistoryTable
        onConfirmDeleteLoanHistory={this.props.onConfirmDeleteLoanHistory}
        personId={this.state.personId}
      />
    </div>
  );

  render() {
    const { form, loggedUser } = this.props;
    const { getFieldDecorator } = form;

    const isAgent = loggedUser?.roles?.includes(RolesTypes.ROLES_TYPES.BACKOFFICE);
    const disableFields = !loggedUser?.id || isAgent;

    return (
      <div>
        <Segment
          title="Atualizar"
          actions={[
            {
              render: () => (
                <Button
                  block
                  disabled={disableFields || this.props.updatingMargin}
                  type="primary"
                  onClick={this.updateMargin}
                  style={{ minWidth: '100px', opacity: disableFields ? 0.5 : 1 }}
                >
                  {this.props.updatingMargin ? 'Salvando...' : 'Salvar'}
                </Button>
              ),
            },
          ]}
        >
          <Form style={{ padding: 16 }} onSubmit={this.updateMargin} className="login-form">
            <Spinner spinning={this.props.updatingMargin} />
            <FirstRow>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6, offset: 0 }}>
                <Title>Margem de Empréstimo:</Title>
                <FormItem>
                  {getFieldDecorator('marginLoan', {
                    normalize: toMoneyForm,
                    disabled: true,
                    rules: [
                      {
                        required: true,
                        message: 'Digite a margem de empréstimo da pessoa',
                      },
                    ],
                  })(
                    <Input
                      style={{ opacity: disableFields ? 0.5 : 1 }}
                      disabled={disableFields}
                      addonBefore="R$"
                      type="text"
                      onChange={this.onMarginLoanChange}
                    />,
                  )}
                </FormItem>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                <Title>Margem de Cartão de Crédito:</Title>
                <FormItem>
                  {getFieldDecorator('marginCard', {
                    normalize: toMoneyForm,
                    rules: [
                      {
                        required: true,
                        message: 'Digite a margem de cartão de crédito da pessoa',
                      },
                    ],
                  })(
                    <Input
                      style={{ opacity: disableFields ? 0.5 : 1 }}
                      disabled={disableFields}
                      addonBefore="R$"
                      type="text"
                      onChange={this.onMarginCardChange}
                    />,
                  )}
                </FormItem>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6, offset: 1 }}>
                <Title>Data de Validade:</Title>
                <FormItem>
                  {getFieldDecorator('marginExpirationDate', {
                    rules: [
                      {
                        required: true,
                        message: 'Selecione a data de validade',
                      },
                    ],
                  })(
                    <DatePicker
                      style={{ opacity: disableFields ? 0.5 : 1 }}
                      disabled={disableFields}
                      onChange={this.onExpirationDateChange}
                      locale={locale}
                      format="DD/MM/YYYY"
                    />,
                  )}
                </FormItem>
              </Col>
            </FirstRow>
          </Form>
        </Segment>
        <Row className="row">
          <Col xs={{ span: 24 }}>
            <div>{this.renderTable(getFieldDecorator)}</div>
          </Col>
        </Row>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    loggedUser: getLoggedUser.getResult()(state),
  };
}

PersonContractExtract.defaultProps = {};

const WrappedPersonContractExtract = Form.create()(PersonContractExtract);

export default compose(connect(mapStateToProps))(WrappedPersonContractExtract);
