import React, { useCallback, cloneElement, useState, useMemo, Fragment, useEffect } from 'react';

import { Collapse } from 'antd';

import { TabPaneProps, TabsType } from 'antd/lib/tabs';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { ampli } from '~/ampli';
import { useTracking } from '~/hooks/useTracking';
import { useAwaitControl } from 'react-redux-await-control';
import { getPersonDetail } from '~/store/people/actions';
import { TabsBody, TabPane, CollapseButton, TabsContainer, Flex } from './Tabs.styled';

const { Panel } = Collapse;

export interface ActionProps {
  disabled?: boolean;
  activeTab?: string;
}

export interface TabItem extends TabPaneProps {
  key: string;
  title: string;
  badge?: any;
  icon?: any;
  component?: any;
  noPadding?: boolean;
}

type Animated =
  | boolean
  | {
      inkBar: boolean;
      tabPane: boolean;
    };

export interface TabsProps {
  name?: string;
  tabs: TabItem[];
  collapsable?: boolean;
  initCollapsed?: boolean;
  extraActions?: React.ReactElement | React.ReactElement[];
  loading?: boolean;
  onChange?: (key: string) => void;
  initialTab?: string;
  persistOnQuery?: { key: string };
  type?: TabsType;
  animated?: Animated;
  fullWidth?: boolean;
  className?: string;
  testId?: string;
}

export function Tabs({
  tabs,
  collapsable,
  initCollapsed = false,
  extraActions,
  loading,
  onChange,
  initialTab,
  persistOnQuery,
  animated,
  type,
  fullWidth,
  testId = 'ant',
  className,
}: TabsProps) {
  const [collapsed, setCollapsed] = useState(initCollapsed);
  const history = useHistory();
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const { handleTracking } = useTracking();
  const personDetails = getPersonDetailsControl.result();

  const currentTab = useMemo<string>(() => {
    if (!persistOnQuery) return null;
    const { search } = history.location;
    const searchParams = queryString.parse(search);
    const tab = searchParams[persistOnQuery.key];
    return Array.isArray(tab) ? tab[0] : tab;
  }, [history.location]);

  const availableTabs = tabs.filter((tab) => !tab.disabled);
  const [activeTab, setActiveTab] = useState(initialTab || currentTab || availableTabs?.[0]?.key);

  const setQueryParams = (key) => {
    const { pathname, search } = history.location;
    const searchParams = queryString.parse(search);
    searchParams[persistOnQuery.key] = key;

    const newSearch = queryString.stringify(searchParams);
    history.push({ pathname, search: newSearch });
  };

  const handleTrackingTab = (key: string) => {
    const tabSelected = tabs.find((tab) => tab.key === key);

    if (tabSelected.title === 'Oportunidades') {
      const event = ampli.formalizacaoIniciouFormalizacao({
        titulo_tela: 'Pessoas',
        titulo_aba: 'Oportunidade',
        cliente_person_id: personDetails.id,
        cliente_convenio: personDetails.agreementName,
        cliente_oportunidade: personDetails.isOpportunity ?? false,
      });

      handleTracking(() => event);
    }

    if (tabSelected.title === 'Matrículas') {
      const event = ampli.integracaoIniciouIntegracao({
        titulo_tela: 'Pessoas',
        titulo_aba: 'Matrículas',
        cliente_person_id: personDetails.id,
        cliente_convenio: personDetails.agreementName,
        cliente_oportunidade: personDetails.isOpportunity ?? false,
      });

      handleTracking(() => event);
    }

    const event = ampli.abasClicouAba({
      titulo_aba: tabSelected.title,
      cliente_person_id: personDetails.id,
      cliente_convenio: personDetails.agreementName,
      cliente_oportunidade: personDetails.isOpportunity ?? false,
    });

    handleTracking(() => event);
  };

  const handleOnChange = useCallback(
    (key) => {
      if (collapsed) {
        setCollapsed(false);
      }

      onChange?.(key);
      setActiveTab(key);

      handleTrackingTab(key);

      if (persistOnQuery) {
        setQueryParams(key);
      }
    },
    [personDetails, collapsed],
  );

  const handleOnCollapse = useCallback(() => {
    if (collapsed) {
      setActiveTab(availableTabs?.[0]?.key);
    }
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed, availableTabs]);

  const componentWithProps = useCallback(
    (component, props?: any) => cloneElement(component, props),
    [cloneElement],
  );

  const tabBarExtraContent = useMemo(() => {
    if (extraActions) {
      return componentWithProps(extraActions, { disabled: collapsed, activeTab });
    }

    return <></>;
  }, [extraActions, collapsed, activeTab]);

  const renderTabTitle = useCallback(
    (title, index, badge?, icon?) => (
      <Flex data-testid={`${testId}-tab-${index}`}>
        {icon} {title} {badge}
      </Flex>
    ),
    [],
  );

  const renderComponent = useCallback(
    (component) => {
      if (component) {
        return componentWithProps(component, { loading: loading || component?.props?.loading });
      }

      return <></>;
    },
    [loading],
  );

  useEffect(() => {
    if (collapsed) {
      setActiveTab(null);
    }
  }, [collapsed]);

  return (
    <TabsContainer>
      {collapsable && <CollapseButton collapsed={collapsed} onClick={handleOnCollapse} />}

      <TabsBody
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={handleOnChange}
        tabBarExtraContent={tabBarExtraContent}
        collapsed={collapsed}
        loading={loading}
        animated={type === 'card' ? false : animated}
        type={type}
        fullWidth={fullWidth}
        className={className}
      >
        {tabs.map(({ key, title, component, badge, icon, ...tabProps }, index) => (
          <TabPane
            tab={renderTabTitle(title, index, badge, icon)}
            key={key}
            data-testid="tab"
            {...tabProps}
          >
            <Collapse
              defaultActiveKey="collapse"
              activeKey={collapsed ? null : 'collapse'}
              bordered={false}
            >
              <Panel showArrow={false} header={null} key="collapse">
                {renderComponent(component)}
              </Panel>
            </Collapse>
          </TabPane>
        ))}
      </TabsBody>
    </TabsContainer>
  );
}
