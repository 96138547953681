import React from 'react';

import { Tag, Typography } from '~/ui/components';
import { FileSearch, LoadingSpinner } from '~/ui/assets/icons';
import { Content, Wrapper, Info, Title } from './History.styled';

type HistoryType = {
  id: number;
  referenceDate: any;
  processedAt: string;
  valid: boolean;
  personPaycheckFileType: string;
};

interface HistoryProps {
  history: HistoryType;
  onClick: (id: number) => void;
  isNew?: boolean;
  isLoading?: boolean;
}

export function History({ history, onClick, isNew = false, isLoading }: HistoryProps) {
  return (
    <Wrapper>
      <Content>
        <Title>
          <Typography type="caption" weight={600}>
            Enviado em: {history?.referenceDate.format('DD/MM/YYYY HH:mm')}
          </Typography>
          {isNew && <Tag color="positive">Novo</Tag>}
        </Title>
        <Info>Tipo de arquivo: {history?.personPaycheckFileType}</Info>
      </Content>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <FileSearch width={24} height={24} onClick={() => onClick(history.id)} />
      )}
    </Wrapper>
  );
}
