/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import Modal from 'react-modal';
import { X } from 'react-feather';
import { isEmpty } from 'underscore';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import fetchContractRefusalReasons from '~/store/fetchContractRefusalReasons/action';
import theme from '~/themes/aphroditeTheme/theme';
import { Spinner, Button } from '~/components';
import styles from './styles';

const { withStyles } = theme;

const { Item: FormItem } = Form;
const { Option } = Select;

const { css } = theme;
import { ampli } from '~/ampli';

export type RequestActionViewProps = {
  confirmButtonTitle: string,
  requestButtonTitle: string,
  confirmWithPasswordMessage: string,
  messageReasonCancel?: string,
  form?: any,
  cancelReasons: [],
  reasonTitle?: string,
  onPressRequest(): () => void,
  modalVisible: boolean,
  isModalVisible(visible: boolean): () => void,
  styles?: any,
  loading?: boolean,
  hideReason?: boolean,
  hidePassword?: boolean,
};

function mapStateToProps(state) {
  return {
    cancelReasons: state.getIn(['fetchContractRefusalReasons', 'payload']),
    isLoadingReasons: state.getIn(['fetchContractRefusalReasons', 'isLoading']),
  };
}

class RequestActionView extends Component<RequestActionViewProps> {
  state = {
    password: '',
    reasonId: null,
    openReasonDescription: '',
  };

  reorderedRequestActionReasons = [];

  handleSubmit = () => {
    const { password, reasonId, openReasonDescription } = this.state;

    ampli.conferenciaConcluiuConferenciaCancelouContrato({
      titulo_tela: 'Contratos',
      cliente_user_id: this.props.loggedUserId,
    });

    ampli.flush()

    this.props.form.validateFields((err, values) => {
      if (!err || isEmpty(err)) {
        this.props.onPressRequest(password, reasonId, openReasonDescription);
      }
    });
  };

  handleScundaryButton = () => {
    const { password } = this.state;

    this.props.form.validateFields((err, values) => {
      if (!err || isEmpty(err)) {
        this.props.onPressScundaryButton(password);
      }
    });
  };

  reorderRequestActionReasons = () => {
    const reasons = this.props.cancelReasons.filter((reason) => reason.description !== 'Outro');

    if (reasons && Array.isArray(reasons)) {
      const reasonsWithoutOtherOption = reasons.filter((reason) => reason.description !== 'Outro');
      const otherRequestActionReason = reasons.filter((reason) => reason.description === 'Outro');
      this.reorderedRequestActionReasons =
        reasonsWithoutOtherOption.concat(otherRequestActionReason);
    }
  };

  onPressOpen = () => {
    const { id, cancelReasons } = this.props;

    if (!cancelReasons.length) {
      this.props.dispatch(fetchContractRefusalReasons(id));
    }
  };

  componentDidUpdate(prevProps) {
    const { cancelReasons, isLoadingReasons } = this.props;

    if (prevProps.cancelReasons !== cancelReasons && cancelReasons.length && !isLoadingReasons) {
      this.props.isModalVisible(true);
      this.reorderRequestActionReasons();
    }
  }

  onPressClose = () => {
    this.props.isModalVisible(false);
  };

  onRequestActionReasonChange = (reasonId) => {
    this.setState({ reasonId });
  };

  onOpenRequestActionReasonDescription = (e) => {
    this.setState({ openReasonDescription: e.target.value });
  };

  onPasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  getOtherReason = () => {
    const { cancelReasons } = this.props;
    let otherReason;

    if (cancelReasons && cancelReasons.length) {
      otherReason = cancelReasons
        .filter((reason) => reason.description === 'Outro')
        .reduce((item) => item);
    }

    return otherReason;
  };

  handleFilterOption = (input, option) => {
    const optionChildren = option.props.children.toLowerCase();
    return optionChildren.indexOf(input.toLowerCase()) >= 0;
  };

  renderOpenReasonInput(): any {
    const { getFieldDecorator } = this.props.form;
    const { cancelReasons } = this.props;
    const { reasonId } = this.state;

    if (!cancelReasons.length) return null;

    const otherReason = this.getOtherReason();

    if (otherReason && otherReason.id !== parseInt(reasonId, 10)) return null;

    return (
      <Row>
        <Col xs={{ span: 24 }}>
          <span>Comentário:</span>
          <FormItem>
            {getFieldDecorator('openReasonDescription', {
              rules: [
                {
                  required: true,
                  message: 'Digite motivo',
                },
              ],
            })(
              <Input.TextArea
                placeholder=""
                onChange={this.onOpenRequestActionReasonDescription}
              />,
            )}
          </FormItem>
        </Col>
      </Row>
    );
  }

  render() {
    const {
      styles,
      cancelReasons,
      hideReason,
      hidePassword,
      reasonTitle,
      titleText,
      confirmButtonTitle,
      secundaryButtonTitle,
      onPressScundaryButton,
      requestButtonTitle,
      messageReasonCancel,
    } = this.props;

    const { getFieldDecorator } = this.props.form;

    const style = {
      overlay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.3s',
        background: 'rgba(0, 0, 0, 0.65)',
        zIndex: 1000,
      },
    };

    return [
      <Button
        key="button"
        type="danger"
        size="large"
        fullWidth
        style={{ marginTop: 16 }}
        loading={this.props.isLoadingReasons}
        onClick={this.onPressOpen}
        title={requestButtonTitle || confirmButtonTitle || 'Solicitar Cancelamento'}
      />,
      <Modal
        ariaHideApp={false}
        contentLabel="Confirm"
        isOpen={this.props.modalVisible}
        key="modal"
        style={style}
        {...css(styles.modal)}
      >
        <Spinner spinning={this.props.isLoadingReasons} />

        <div {...css(styles.modal__header)}>
          <span {...css(styles.modal__title)}>{titleText}</span>
          <button onClick={this.onPressClose} {...css(styles.modal__close)}>
            <X {...css(styles.closeButton)} size={20} />
          </button>
        </div>
        <Form onSubmit={this.handleSubmit} className="cancel-form">
          {cancelReasons.length && this.reorderedRequestActionReasons && !hideReason ? (
            <Row>
              <Col xs={{ span: 24 }}>
                <span>{reasonTitle}</span>
                <FormItem>
                  {getFieldDecorator('reasonId', {
                    rules: [
                      {
                        required: true,
                        message: messageReasonCancel,
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Selecione"
                      onChange={this.onRequestActionReasonChange}
                      filterOption={this.handleFilterOption}
                      allowClear
                    >
                      {this.reorderedRequestActionReasons.map((item) => (
                        <Option key={item.id}>{item.description}</Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
          ) : null}
          {this.renderOpenReasonInput()}
          {!hidePassword && (
            <Row>
              <Col xs={{ span: 24 }}>
                <span>Senha:</span>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: 'Digite sua senha',
                      },
                    ],
                  })(
                    <Input
                      type="password"
                      placeholder="Digite sua senha"
                      onChange={this.onPasswordChange}
                    />,
                  )}
                </FormItem>
              </Col>
            </Row>
          )}
          <Row type="flex" justify="space-between" style={{ marginTop: '18px' }}>
            <Col>
              <Button type="danger" onClick={this.handleSubmit}>
                {requestButtonTitle || confirmButtonTitle}
              </Button>
            </Col>
            {secundaryButtonTitle && onPressScundaryButton && (
              <Col>
                <Button type="primary" ghost onClick={this.handleScundaryButton}>
                  {secundaryButtonTitle}
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>,
    ];
  }
}

RequestActionView.defaultProps = {
  styles: {},
  hideReason: false,
  messageReasonCancel: '',
  reasonTitle: 'Motivo:',
  form: {},
  loading: false,
};

export default compose(connect(mapStateToProps), withStyles(styles))(RequestActionView);
