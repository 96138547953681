/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 2
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'backofficehomolog';

export const ApiKey: Record<Environment, string> = {
  backofficehomolog: 'a8f54f591402db8fa2d3ccfe224d6a36'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '2',
    branch: 'tests',
    source: 'web',
    versionId: '6c3a200f-fa7b-45bb-a9b8-8abbd5b62264'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface AbasClicouAbaProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_aba?: string;
}

export interface ConferenciaConcluiuConferenciaCancelouContratoProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export interface ConferenciaConcluiuConferenciaEnviouContratoProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export interface ConferenciaConcluiuConferenciaGerouPendenciaProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export interface ConferenciaIniciouConferenciaProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export interface FormalizacaoConcluiuFormalizacaoProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_aba?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export interface FormalizacaoIniciouFormalizacaoProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_aba?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export interface IntegracaoConcluiuIntegracaoProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_aba?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export interface IntegracaoIniciouIntegracaoProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_aba?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export interface PeopleTicketsConcluiuAbrirTicketProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export interface PeopleTicketsIniciouAbrirTicketProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export interface SimulacaoConcluiuSimulacaoProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_aba?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export interface SimulacaoIniciouSimulacaoProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_aba?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export interface VisualizacaoVisualizouTelaProperties {
  cliente_contrato_pago?: string;
  cliente_convenio?: string;
  cliente_integrado?: string;
  cliente_oportunidade?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cliente_person_id?: number;
  cliente_user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  titulo_tela?: string;
}

export class AbasClicouAba implements BaseEvent {
  event_type = 'abas_clicou_aba';

  constructor(
    public event_properties?: AbasClicouAbaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConferenciaConcluiuConferenciaCancelouContrato implements BaseEvent {
  event_type = 'conferencia_concluiu_conferencia_cancelou_contrato';

  constructor(
    public event_properties?: ConferenciaConcluiuConferenciaCancelouContratoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConferenciaConcluiuConferenciaEnviouContrato implements BaseEvent {
  event_type = 'conferencia_concluiu_conferencia_enviou_contrato';

  constructor(
    public event_properties?: ConferenciaConcluiuConferenciaEnviouContratoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConferenciaConcluiuConferenciaGerouPendencia implements BaseEvent {
  event_type = 'conferencia_concluiu_conferencia_gerou_pendencia';

  constructor(
    public event_properties?: ConferenciaConcluiuConferenciaGerouPendenciaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConferenciaIniciouConferencia implements BaseEvent {
  event_type = 'conferencia_iniciou_conferencia';

  constructor(
    public event_properties?: ConferenciaIniciouConferenciaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FormalizacaoConcluiuFormalizacao implements BaseEvent {
  event_type = 'formalizacao_concluiu_formalizacao';

  constructor(
    public event_properties?: FormalizacaoConcluiuFormalizacaoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FormalizacaoIniciouFormalizacao implements BaseEvent {
  event_type = 'formalizacao_iniciou_formalizacao';

  constructor(
    public event_properties?: FormalizacaoIniciouFormalizacaoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IntegracaoConcluiuIntegracao implements BaseEvent {
  event_type = 'integracao_concluiu_integracao';

  constructor(
    public event_properties?: IntegracaoConcluiuIntegracaoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IntegracaoIniciouIntegracao implements BaseEvent {
  event_type = 'integracao_iniciou_integracao';

  constructor(
    public event_properties?: IntegracaoIniciouIntegracaoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PeopleTicketsConcluiuAbrirTicket implements BaseEvent {
  event_type = 'people_tickets_concluiu_abrir_ticket';

  constructor(
    public event_properties?: PeopleTicketsConcluiuAbrirTicketProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PeopleTicketsIniciouAbrirTicket implements BaseEvent {
  event_type = 'people_tickets_iniciou_abrir_ticket';

  constructor(
    public event_properties?: PeopleTicketsIniciouAbrirTicketProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SimulacaoConcluiuSimulacao implements BaseEvent {
  event_type = 'simulacao_concluiu_simulacao';

  constructor(
    public event_properties?: SimulacaoConcluiuSimulacaoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SimulacaoIniciouSimulacao implements BaseEvent {
  event_type = 'simulacao_iniciou_simulacao';

  constructor(
    public event_properties?: SimulacaoIniciouSimulacaoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisualizacaoVisualizouTela implements BaseEvent {
  event_type = 'visualizacao_visualizou_tela';

  constructor(
    public event_properties?: VisualizacaoVisualizouTelaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * abas_clicou_aba
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/abas_clicou_aba)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  abasClicouAba(
    properties?: AbasClicouAbaProperties,
    options?: EventOptions,
  ) {
    return this.track(new AbasClicouAba(properties), options);
  }

  /**
   * conferencia_concluiu_conferencia_cancelou_contrato
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/conferencia_concluiu_conferencia_cancelou_contrato)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  conferenciaConcluiuConferenciaCancelouContrato(
    properties?: ConferenciaConcluiuConferenciaCancelouContratoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConferenciaConcluiuConferenciaCancelouContrato(properties), options);
  }

  /**
   * conferencia_concluiu_conferencia_enviou_contrato
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/conferencia_concluiu_conferencia_enviou_contrato)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  conferenciaConcluiuConferenciaEnviouContrato(
    properties?: ConferenciaConcluiuConferenciaEnviouContratoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConferenciaConcluiuConferenciaEnviouContrato(properties), options);
  }

  /**
   * conferencia_concluiu_conferencia_gerou_pendencia
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/conferencia_concluiu_conferencia_gerou_pendencia)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  conferenciaConcluiuConferenciaGerouPendencia(
    properties?: ConferenciaConcluiuConferenciaGerouPendenciaProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConferenciaConcluiuConferenciaGerouPendencia(properties), options);
  }

  /**
   * conferencia_iniciou_conferencia
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/conferencia_iniciou_conferencia)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  conferenciaIniciouConferencia(
    properties?: ConferenciaIniciouConferenciaProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConferenciaIniciouConferencia(properties), options);
  }

  /**
   * formalizacao_concluiu_formalizacao
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/formalizacao_concluiu_formalizacao)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  formalizacaoConcluiuFormalizacao(
    properties?: FormalizacaoConcluiuFormalizacaoProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormalizacaoConcluiuFormalizacao(properties), options);
  }

  /**
   * formalizacao_iniciou_formalizacao
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/formalizacao_iniciou_formalizacao)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  formalizacaoIniciouFormalizacao(
    properties?: FormalizacaoIniciouFormalizacaoProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormalizacaoIniciouFormalizacao(properties), options);
  }

  /**
   * integracao_concluiu_integracao
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/integracao_concluiu_integracao)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  integracaoConcluiuIntegracao(
    properties?: IntegracaoConcluiuIntegracaoProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegracaoConcluiuIntegracao(properties), options);
  }

  /**
   * integracao_iniciou_integracao
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/integracao_iniciou_integracao)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  integracaoIniciouIntegracao(
    properties?: IntegracaoIniciouIntegracaoProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegracaoIniciouIntegracao(properties), options);
  }

  /**
   * people_tickets_concluiu_abrir_ticket
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/people_tickets_concluiu_abrir_ticket)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  peopleTicketsConcluiuAbrirTicket(
    properties?: PeopleTicketsConcluiuAbrirTicketProperties,
    options?: EventOptions,
  ) {
    return this.track(new PeopleTicketsConcluiuAbrirTicket(properties), options);
  }

  /**
   * people_tickets_iniciou_abrir_ticket
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/people_tickets_iniciou_abrir_ticket)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  peopleTicketsIniciouAbrirTicket(
    properties?: PeopleTicketsIniciouAbrirTicketProperties,
    options?: EventOptions,
  ) {
    return this.track(new PeopleTicketsIniciouAbrirTicket(properties), options);
  }

  /**
   * simulacao_concluiu_simulacao
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/simulacao_concluiu_simulacao)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  simulacaoConcluiuSimulacao(
    properties?: SimulacaoConcluiuSimulacaoProperties,
    options?: EventOptions,
  ) {
    return this.track(new SimulacaoConcluiuSimulacao(properties), options);
  }

  /**
   * simulacao_iniciou_simulacao
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/simulacao_iniciou_simulacao)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  simulacaoIniciouSimulacao(
    properties?: SimulacaoIniciouSimulacaoProperties,
    options?: EventOptions,
  ) {
    return this.track(new SimulacaoIniciouSimulacao(properties), options);
  }

  /**
   * visualizacao_visualizou_tela
   *
   * [View in Tracking Plan](https://data.amplitude.com/meutudo-data/backoffice%20-%20homolog/events/tests/latest/visualizacao_visualizou_tela)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cliente_contrato_pago)
   * @param options Amplitude event options.
   */
  visualizacaoVisualizouTela(
    properties?: VisualizacaoVisualizouTelaProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisualizacaoVisualizouTela(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
