import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import _ from 'lodash';

import { Alert, Typography } from '~/ui/components';
import { CaretLeft, CaretRight, SearchInBox, Star } from '~/ui/assets/icons';
import { Person } from '~/typings/entities/person';
import { getOpportunities } from '~/store/opportunities/actions';
import { OpportunityListItem } from '~/typings/entities/Opportunity';
import { getPersonDetail } from '~/store/people/actions';
import {
  CollapseButton,
  Content,
  ContentSection,
  Section,
  Title,
  Wrapper,
} from './Opportunities.styled';
import { Card } from './components/Card/Card';
import { CardShimmer } from './components/CardShimmer/CardShimmer';

interface OpportunitiesProps {
  person: Person;
}

export function Opportunities({ person }: OpportunitiesProps) {
  const [visibility, setVisibility] = useState(true);
  const itemsRef = useRef(null);

  const opportunitiesControl = useAwaitControl(getOpportunities);
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);

  const opportunitiesLoading = opportunitiesControl.isRunning();
  const personLoading = getPersonDetailsControl.isRunning();

  const opportunities: OpportunityListItem[] = opportunitiesControl.result();

  const handleCollapse = useCallback(() => {
    setVisibility(!visibility);
  }, [visibility]);

  const handlePreviousItem = useCallback(() => {
    itemsRef.current.scrollBy({ left: -270, behavior: 'smooth' });
  }, []);

  const handleNextItem = useCallback(() => {
    itemsRef.current.scrollBy({ left: 270, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const wheelEvent = (event) => {
      if (event.deltaY > 0) {
        handleNextItem();
      } else {
        handlePreviousItem();
      }
    };

    const itemsElement = itemsRef.current;
    itemsElement?.addEventListener('wheel', wheelEvent);

    return () => {
      itemsElement?.removeEventListener('wheel', wheelEvent);
    };
  }, [handleNextItem, handlePreviousItem]);

  useEffect(() => {
    if (!_.isEmpty(person) && person?.registrationStatus !== 'REFUSED') {
      opportunitiesControl.start({ personId: person?.id, agreementId: person?.agreementId });
    }
  }, [person]);

  return (
    <Wrapper>
      <Title>
        <ContentSection onClick={handleCollapse} pointer>
          <CollapseButton collapsed={!visibility} onClick={handleCollapse} />
          <Star />
          <Typography type="bodyLarge" weight={600}>
            Oportunidades
          </Typography>
        </ContentSection>
        {opportunities && opportunities.length > 0 && (
          <ContentSection>
            <CaretRight width={14} height={14} onClick={handlePreviousItem} />
            <CaretRight width={14} height={14} onClick={handleNextItem} />
          </ContentSection>
        )}
      </Title>
      {visibility && (
        <Content ref={itemsRef}>
          {(opportunitiesLoading || personLoading) &&
            Array.from({ length: 4 }).map((_, index) => <CardShimmer key={index} />)}
          {opportunities?.slice(0, 10).map((opportunity, index) => (
            <Card key={index} opportunity={opportunity} />
          ))}
          {!personLoading && !opportunitiesLoading && !opportunities?.length && (
            <Section>
              <SearchInBox width={94} height={58} />
              <Typography type="caption" weight={500}>
                Sem oportunidades no momento
              </Typography>
            </Section>
          )}
        </Content>
      )}
    </Wrapper>
  );
}
