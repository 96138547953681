import { ampli } from '../src/ampli';

export const setupAmplitude = () => {
  ampli.load({
    client: {
      apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY ?? '',
      configuration: {
        autocapture: {
          sessions: false,
          pageViews: false,
          attribution: false,
          formInteractions: false,
        },
        defaultTracking: {
          sessions: false,
        },
      },
    },
  });
};
