import React from 'react';
import { remoteValues } from '~/common/utils/firebase/remoteConfig';

import { SideActions } from '~/components';
import { DisapproveCustomer } from '~/components/OtherOptions/components/DisapproveCustomer';
import { AccessByCostumerButton } from '~/components/SideActions/PersonActions/components';

type SideBarActionsProps = {
  loading?: boolean;
  className?: string;
};

function SideBarActions({ loading, className }: SideBarActionsProps) {
  const { enableAccessByClient } = remoteValues;

  const actions = [{ component: <DisapproveCustomer /> }];

  if (enableAccessByClient) {
    actions.push({ component: <AccessByCostumerButton /> });
  }

  return <SideActions className={className} loading={loading} actions={actions} />;
}

export default SideBarActions;
