import React, { useCallback, useMemo } from 'react';
import { ClearSaleScoreItem } from '~/typings/entities/person';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { getReliability } from '~/common/utils/clearSale';
import formatScores, { ScoreHistoryItem, ScoreSteps } from '~/common/formatScores';

import {
  Timeline,
  TimelineDate,
  TimelineItem,
  TimelineText,
  TimelineTitle,
} from './ClearSaleScore.styled';

export type ClearSaleHistoryProps = {
  scoreHistory: ClearSaleScoreItem[];
};

const ScoreStepTitle = {
  [ScoreSteps.initial]: 'Score inicial',
  [ScoreSteps.emailValidated]: 'Validação de token',
  [ScoreSteps.smsValidated]: 'Validação de token',
  [ScoreSteps.emailNotValidated]: 'Validação de token',
  [ScoreSteps.smsNotValidated]: 'Validação de token',
  [ScoreSteps.change]: 'Mudança de score',
  [ScoreSteps.tokenSentSMS]: 'Envio de token',
  [ScoreSteps.tokenSentEmail]: 'Envio de token',
  [ScoreSteps.tokenSentBoth]: 'Envio de token',
};

const ScoreStepText = {
  [ScoreSteps.emailValidated]: 'Token validado por E-mail',
  [ScoreSteps.emailNotValidated]: 'Token não validado por E-mail',
  [ScoreSteps.smsValidated]: 'Token validado por SMS',
  [ScoreSteps.smsNotValidated]: 'Token não validado por SMS',
  [ScoreSteps.tokenSentSMS]: 'Envio de token por SMS',
  [ScoreSteps.tokenSentEmail]: 'Envio de token por E-mail',
  [ScoreSteps.tokenSentBoth]: 'Envio de token por SMS e E-mail',
};

export function ClearSaleHistory({ scoreHistory }: ClearSaleHistoryProps) {
  const theme = useTheme();

  const formattedHistory: ScoreHistoryItem[] = useMemo(
    () => formatScores(scoreHistory),
    [scoreHistory],
  );

  const formatData = (date: string, withoutHours = false) => {
    if (date) {
      const format = withoutHours ? 'DD [de] MMM, YYYY' : 'DD [de] MMM, YYYY [às] HH:mm[h]';
      return moment(date).format(format);
    }

    return '-';
  };

  const renderReliabilityText = (item: ScoreHistoryItem) => {
    const auxiliaryText = ScoreStepText[item.step];
    const reliability = getReliability(item.score);
    return auxiliaryText || reliability?.label;
  };

  const getReliabilityColor = useCallback(
    (scoreItem: ScoreHistoryItem) => {
      if (
        [ScoreSteps.tokenSentSMS, ScoreSteps.tokenSentEmail, ScoreSteps.tokenSentBoth].includes(
          scoreItem.step,
        )
      ) {
        return theme.colors.primary[600];
      }

      if ([ScoreSteps.emailValidated, ScoreSteps.smsValidated].includes(scoreItem.step)) {
        return theme.colors.tertiary[600];
      }

      const reliability = getReliability(scoreItem.score);

      return reliability?.color || theme.colors.gray[600];
    },
    [getReliability, theme],
  );

  return (
    <Timeline>
      {formattedHistory?.map((item) => (
        <TimelineItem key={`${item.date}_${item.score}`} color={getReliabilityColor(item)}>
          <TimelineTitle>{ScoreStepTitle[item.step]}</TimelineTitle>
          <TimelineText>
            {renderReliabilityText(item)}
            {item.score && (
              <>
                {' '}
                - <b>{item.score}%</b>
              </>
            )}
          </TimelineText>
          {item.date && (
            <TimelineDate>{formatData(item.date, item.step === ScoreSteps.initial)}</TimelineDate>
          )}
        </TimelineItem>
      ))}
    </Timeline>
  );
}
