import styled from 'styled-components';

import { Button } from '~/ui/components';

export const StyledButton = styled(Button).attrs({
  size: 'md',
  color: 'primary',
})`
  border-radius: var(--radius-200, 16px);
  width: 136px;
  height: 42px;

  span {
    font-size: 14px;
  }

  svg {
    width: 22px;
    height: 22px;
  }
`;
