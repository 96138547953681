import { TooltipPlacement, TooltipTrigger } from 'antd/lib/tooltip';
import React, { ReactElement, useMemo, useState } from 'react';
import { PopoverCard } from '../PopoverCard/PopoverCard';
import { Popover as PopoverComponent } from './Popover.styled';

export interface PopoverProps {
  title?: string;
  children: React.ReactNode;
  placement?: TooltipPlacement;
  trigger?: TooltipTrigger;
  content: ReactElement;
  visible?: boolean;
  noHeader?: boolean;
  width?: number | string;
  onVisibleChange?: (visible: boolean) => void;
  disabled?: boolean;
  relative?: boolean;
  noPadding?: boolean;
  noArrow?: boolean;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  destroyTooltipOnHide?: boolean;
  id?: string;
}

export interface PopoverContentProps {
  closePopover: () => void;
}

export function Popover({
  children,
  content,
  title,
  noHeader,
  width,
  relative,
  noPadding,
  noArrow,
  getPopupContainer,
  id,
  ...args
}: PopoverProps) {
  const [visible, setVisible] = useState(false);

  const customPopoverProps = useMemo(() => {
    const getContainer = (trigger) => {
      if (getPopupContainer) return getPopupContainer(trigger);

      return relative ? trigger.parentElement : document.body;
    };

    const noPaddingClass = noPadding ? 'mt-custom-popover-no-padding' : '';
    const noArrowClass = noArrow ? 'mt-custom-popover-no-arrow' : '';

    const overlayClassName = [noPaddingClass, noArrowClass].join(' ');

    return {
      getPopupContainer: getContainer,
      overlayClassName,
    };
  }, [relative, getPopupContainer]);

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const Card = (
    <PopoverCard noHeader={noHeader} title={title} setVisible={setVisible}>
      {content}
    </PopoverCard>
  );

  return (
    <PopoverComponent
      visible={visible}
      content={Card}
      onVisibleChange={handleVisibleChange}
      width={width}
      {...args}
      {...customPopoverProps}
      id={id}
    >
      {children}
    </PopoverComponent>
  );
}
