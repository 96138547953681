import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 24px;
  height: calc(100vh - 83px);
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
`;

type SectionProps = {
  gap?: number;
  maxWidth?: number;
  center?: boolean;
};

export const Section = styled.div<SectionProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || 16}px;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}

  ${({ center }) =>
    center &&
    css`
      align-self: center;
      align-items: center;
    `}

    h6 {
    margin-bottom: 8px;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Card = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 22px;
  width: 100%;
  border: 1.5px solid ${({ theme }) => theme.colors.element.disabled};
  border-radius: 10px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: auto;
    `}

  :hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        border-color: ${({ theme }) => theme.colors.brand.primary};
      `}
  }

  svg {
    color: ${({ theme }) => theme.colors.brand.primary};
  }
`;
