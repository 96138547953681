import React from 'react';
import { render } from 'react-dom';
import MainApp from './MainApp';
import { keycloakInit } from './common/utils/keycloak';
import { initRemoteConfig } from './common/utils/firebase/remoteConfig/remoteConfig.ts';
import { setupSentry } from '../config/sentry.js';
import { setupGTM } from '../config/tagManager.ts';
import { setupAmplitude } from '../config/amplitude.ts';

const container = document.getElementById('root');

function initializeConfigs() {
  setupSentry();
  setupGTM();
  setupAmplitude()
}

async function initializeAsyncConfigs() {
  try {
    await initRemoteConfig();
  } catch (e) {
    console.warn('ATENÇÃO!!! Erro ao carregar remote config. Erro:', e);
  }

  await keycloakInit();
}

function renderApp() {
  render(<MainApp />, container);
}

async function startAplication() {
  initializeConfigs();
  await initializeAsyncConfigs();
  renderApp();
}


startAplication();
