import React from 'react';

import { TicketModal } from '~/components';

import { useDrawer } from '~/hooks/useDrawer';
import { ampli } from '~/ampli';
import { useTracking } from '~/hooks/useTracking';
import { useAwaitControl } from 'react-redux-await-control';
import { getPersonDetail } from '~/store/people/actions';
import { StyledButton } from './TicketButton.styled';

export function TicketButton() {
  const { openDrawer } = useDrawer();
  const { handleTracking } = useTracking();
  const personControl = useAwaitControl(getPersonDetail);
  const personDetails = personControl.result();

  const handleOpenTicket = () => {
    const event = ampli.peopleTicketsIniciouAbrirTicket({
      titulo_tela: 'PersonDetailsNew',
      cliente_person_id: personDetails.id,
      cliente_convenio: personDetails.agreementName,
      cliente_oportunidade: personDetails.isOpportunity ?? false,
    });

    handleTracking(() => event);
    openDrawer('ticket-modal', <TicketModal />, {
      title: 'Registre seu ticket',
      width: 460,
      closable: true,
      maskClosable: false,
    });
  };

  return (
    <StyledButton
      title="Abrir ticket"
      onClick={handleOpenTicket}
      data-testid="ticket-open-button"
    />
  );
}
