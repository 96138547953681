import styled, { css } from 'styled-components';
import { fadeInTop } from '~/ui/theme/animations';
import { Button, Typography } from '~/ui/components';
import { themeColor } from '~/common/utils/theme';

export const SelectContainer = styled.div.attrs({
  className: 'select-date',
})`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

type OpenProp = {
  open?: boolean;
};

type AsFilterProp = {
  asFilter?: boolean;
};

type SelectButtonProps = OpenProp & AsFilterProp;

export const SelectButton = styled.div.attrs({
  className: 'select-date-button',
})<SelectButtonProps>`
  background: ${themeColor('background.secondary', { dark: 'background.primary' })};
  height: ${({ asFilter }) => (asFilter ? '40px' : '56px')};
  border-radius: ${({ asFilter }) => (asFilter ? '32px' : '8px 8px 0 0')};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.element.placeholder};
  cursor: pointer;
  gap: 8px;
  padding: ${({ asFilter }) => (asFilter ? '0 16px' : '8px')};
  border: none;

  ${({ open, asFilter }) =>
    open &&
    asFilter &&
    css`
      .caret {
        transform: rotate(90deg);
      }
    `}

  &:hover {
    ${({ withValue }) =>
      withValue &&
      css`
        .caret {
          display: none;
        }
        .close {
          display: flex;
        }
      `}
  }

  ${({ asFilter }) =>
    !asFilter &&
    css`
      font-size: 16px;
      line-height: 24px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
    `}

  ${({ withValue }) =>
    withValue &&
    css`
      color: ${({ theme }) => theme.colors.element.primary};
    `}
`;

export const SelectOptions = styled.div`
  background: ${({ theme }) => theme.colors.background.primary};
  border-radius: 16px;
  padding: 8px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  width: fit-content;
  overflow: hidden;
  margin-top: 4px;
  animation: 200ms ${fadeInTop};
  position: absolute;
  z-index: 9;
  margin-left: 4px;
`;

export const ActionIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;

  .close {
    display: none;
  }

  .caret {
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    width: 18px;
    height: 12px;
    transition: all 200ms;
    padding: 0 3px;
  }
`;

export const CloseButton = styled(Button).attrs(({ theme }) => ({
  icon: 'CloseCircle',
  iconOnly: true,
  variant: 'text',
  customColor: theme.colors.element.disabled,
  size: 'sm',
  type: 'button',
}))`
  min-width: 18px;
  max-width: 30px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const ValueContainer = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  gap: 8px;
`;

type SelectLabelProps = {
  withValue?: boolean;
};

export const SelectLabel = styled(Typography).attrs({
  element: 'label',
  type: 'bodyMedium',
  color: 'element.placeholder',
})<SelectLabelProps>`
  position: absolute;
  transform: translate(0, 0);
  pointer-events: none;
  z-index: 8;
  transition-property: transform, font-size, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  ${({ withValue }) =>
    withValue &&
    css`
      font-size: ${({ theme }) => theme.typography.types.bodyXSmall.size} !important;
      font-weight: ${({ theme }) => theme.typography.types.bodyXSmall.weight};
      line-height: ${({ theme }) => theme.typography.types.bodyXSmall.lineHeight};
      color: ${({ theme }) => theme.colors.element.secondary};
      transform: translate(0px, -10px);
    `}
`;

export const SelectValue = styled.div<AsFilterProp>`
  transform: translate(0px, ${({ asFilter }) => (asFilter ? '0' : '12px')});
`;

export const Input = styled.input`
  transform: translate(0px, 12px);
  z-index: 2;
  position: absolute;
  background: ${({ theme }) => theme.colors.background.secondary};
  border: none;

  &:focus {
    outline: none;
  }

  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`;
