const ROLES_TYPES = {
  ADMINISTRATOR: 'Administrador',
  BACKOFFICE: 'Agente de Backoffice',
  BACKOFFICE_SUPERVISOR: 'Supervisor de Backoffice',
  TEAM_BLUE: 'Time Azul',
  TEAM_RED: 'Time Vermelho',
  TEAM_ORANGE: 'Time Laranja',
  TEAM_GREEN: 'Time Verde',
};

function RolesTypes(value) {
  if (value) {
    return ROLES_TYPES[value] || value;
  }
  return null;
}

RolesTypes.ROLES_TYPES = Object.keys(ROLES_TYPES).reduce((a, v) => ({ ...a, [v]: v }), {});

export default RolesTypes;
