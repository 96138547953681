import Immutable from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { pick } from 'underscore';
import {
  compose,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withState,
  mapProps,
} from 'recompose';

import { Form } from 'antd';
import message from '~/common/message';

import { getContractDetails } from '~/store/contracts/actions';

import requestRefusalContract, {
  REQUEST_REFUSAL_CONTRACT_REQUEST,
  REQUEST_REFUSAL_CONTRACT_SUCCESS,
  REQUEST_REFUSAL_CONTRACT_FAILURE,
} from '~/store/requestRefusalContract/action';
import { useAwaitControl } from 'react-redux-await-control';

import fetchContractRefusalReasons from '~/store/fetchContractRefusalReasons/action';
import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';

import Request from '~/components/RequestAction/Button/Request';
import { getLoggedUser } from '~/store/user/actions';
import { getPersonDetail } from '~/store/people/actions';


function View(props: { isRequestable: boolean }) {
  const loggedUserId = useAsyncActionStatus(getLoggedUser)?.result?.id;
  const personControl = useAwaitControl(getPersonDetail);
  const personDetails = personControl.result();


  return (
    <Request
      hidePassword
      isRequestable={props.isRequestable}
      reasonTitle="Motivo do cancelamento"
      requestButtonTitle="Cancelar contrato"
      titleText="Confirme o cancelamento do contrato"
      messageReasonCancel="Selecione a razão do cancelamento do contrato"
      loggedUserId={loggedUserId}
      personDetails={personDetails}
      {...props}
    />
  );
}

function componentDidMount() { }

export function onPressRequestRefusalContract({ dispatch, match }) {
  return (password, contractRefusalReasonId, openRefusalReasonDescription) => {
    const { id } = match.params;
    dispatch(
      requestRefusalContract(id, password, contractRefusalReasonId, openRefusalReasonDescription),
    );
  };
}

export function mapStateToProps(state) {
  return {
    details: getContractDetails.getResult()(state),
    detailsSuccess: getContractDetails.isSuccessful()(state),
    detailsFailure: getContractDetails.hasFailure()(state),
    requestRefusalContract: state.getIn(['requestRefusalContract']),
    reasons: state.getIn(['fetchContractRefusalReasons', 'payload']),
  };
}

export function receiveChanges(
  prevProps,
  {
    changeRequestable,
    details,
    detailsSuccess,
    detailsFailure,
    requestRefusalContract,
    dispatch,
    match,
    isModalVisible,
    isLoading,
  },
) {
  if (prevProps.detailsSuccess !== detailsSuccess || prevProps.detailsFailure !== detailsFailure) {
    const { isRefusalRequested = false } = details;
    changeRequestable(!isRefusalRequested);
    return false;
  }

  if (Immutable.is(prevProps.requestRefusalContract, requestRefusalContract) === false) {
    switch (requestRefusalContract.getIn(['type'])) {
      case REQUEST_REFUSAL_CONTRACT_REQUEST: {
        isLoading(true);
        return true;
      }
      case REQUEST_REFUSAL_CONTRACT_SUCCESS: {
        isLoading(false);
        isModalVisible(false);
        message.success('Cancelamento Realizado com Sucesso.');

        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));

        return true;
      }
      case REQUEST_REFUSAL_CONTRACT_FAILURE: {
        const errorStatus = requestRefusalContract.getIn(['payload', 'status']);
        const errorMessage = requestRefusalContract.getIn(['payload', 'message']);

        isLoading(false);
        isModalVisible(false);

        if (errorStatus === 403) {
          message.error(errorMessage);
        } else {
          message.error('Falha no Cancelamento do Contrato.');
        }

        return true;
      }
      default:
        return false;
    }
  }

  return {};
}

export function propagateStateChangeToProps({ isRequestable }) {
  return {
    isRequestable,
  };
}

const WrappedRequestRefusal = Form.create()(View);

export default compose(
  withRouter,
  connect(mapStateToProps),
  lifecycle({
    componentDidMount,
  }),
  withState('loading', 'isLoading', false),
  withState('modalVisible', 'isModalVisible', false),
  withState('isRequestable', 'changeRequestable', false),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withHandlers({
    onPressRequest: onPressRequestRefusalContract,
  }),
  mapProps((props) =>
    pick(
      props,
      ...[
        'reasons',
        'modalVisible',
        'isModalVisible',
        'loading',
        'isLoading',
        'isRequestable',
        'onPressRequest',
      ],
    ),
  ),
)(WrappedRequestRefusal);
