export const LOAN_TYPE_NAMES = {
  NEW: 'Empréstimo Novo',
  FGTS_NEW: 'FGTS',
  PAB: 'PAB',
  CLIX: 'Clix',
  CLIX_CLEAN: 'Clix Clean',
  CLIX_BENEFITS_CARD: 'Clix Cartão Benefício',
  CLIX_CREDIT_CARD: 'Clix Cartão de crédito',
  PORTABILITY: 'Portabilidade',
  PORTABILITYWITHOUTREFIN: 'Portabilidade sem refin',
  PORT_REFIN: 'Portabilidade com refin',
  PORT_WITH_MARGIN: 'Portabilidade',
  PORT_WITHOUT_MARGIN: 'Portabilidade',
  REFIN: 'Refinanciamento',
  FUTUREMARGIN: 'Margem Futura',
  GENERAL_STATUS: 'Status Gerais',
  INSURANCE: 'Seguro',
  NEW_CREDIT_LINES: 'Novas linhas de crédito',
  SHOPPING: 'Loja Meutudo',
  INCOME: 'Seguro Renda',
  PIX_CREDIT_CARD: 'Pix cartão de crédito',
  CONSIGNED_CARD_RMC: 'Cartão consignado',
  BENEFIT_CARD_RCC: 'Cartão benefício', // TODO:Remove this line after fixing the backend
  LIFE_INSURANCE: 'Seguro de Vida',
  CONSIGNED_PIX: 'Pix Consignado - INSS',
};

export const LOAN_TYPES = {
  NEW: 'NEW',
  PORTABILITY: 'PORTABILITY',
  PORTABILITYWITHOUTREFIN: 'PORTABILITYWITHOUTREFIN',
  REFIN: 'REFIN',
  FUTUREMARGIN: 'FUTUREMARGIN',
  FGTS_NEW: 'FGTS_NEW',
  CLIX: 'CLIX',
  PAB: 'PAB',
  PIX_CREDIT_CARD: 'PIX_CREDIT_CARD',
  CONSIGNED_CARD_RMC: 'CONSIGNED_CARD_RMC',
  BENEFIT_CARD_RCC: 'BENEFIT_CARD_RCC',
  PORT_REFIN: 'PORT_REFIN',
  LIFE_INSURANCE: 'LIFE_INSURANCE',
  CONSIGNED_PIX: 'CONSIGNED_PIX',
  PORT_WITH_MARGIN: 'PORT_WITH_MARGIN',
  PORT_WITHOUT_MARGIN: 'PORT_WITHOUT_MARGIN',
};

function LoanType(props: any) {
  if (props) {
    return LOAN_TYPE_NAMES[props.value] || LOAN_TYPE_NAMES[props] || null;
  }
  return null;
}

LoanType.LOAN_TYPES = LOAN_TYPES;

export type Loans = keyof typeof LOAN_TYPES;

export default LoanType;
