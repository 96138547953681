import React, { useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { OpportunityListItem } from '~/typings/entities/Opportunity';
import { Person } from '~/typings/entities/person';
import { getTicketsByUser } from '~/store/tickets/actions';
import { getOpportunities } from '~/store/opportunities/actions';
import { TabItem, Tabs } from '~/ui/components';

import { Contracts, GeneralHistory, Opportunities, Pendencies, Resets, Tickets } from '../Tabs';
import { Enrollments } from '../Tabs/Enrollment/Enrollment';
import { Files } from '../Tabs/Files/Files';

interface AuxiliaryInfoProps {
  loading?: boolean;
  person: Person;
}

export function AuxiliaryInfo({ person, loading }: AuxiliaryInfoProps) {
  const getTicketsByUserControl = useAwaitControl(getTicketsByUser);
  const opportunitiesControl = useAwaitControl(getOpportunities);
  const opportunities: OpportunityListItem[] = opportunitiesControl.result();
  const isTicketsLoading = getTicketsByUserControl.isRunning();

  const tabs = useMemo(() => {
    const tabsList: TabItem[] = [
      {
        key: 'tickets',
        title: 'Tickets',
        noPadding: true,
        component: <Tickets />,
      },
      {
        key: 'contratos',
        title: 'Contratos',
        noPadding: true,
        component: <Contracts />,
      },
      {
        key: 'opportunities',
        title: 'Oportunidades',
        noPadding: true,
        component: <Opportunities />,
      },
      {
        key: 'matriculas',
        title: 'Matrículas',
        noPadding: true,
        component: <Enrollments />,
      },
      {
        key: 'history',
        title: 'Histórico geral',
        component: <GeneralHistory loading={loading} />,
        noPadding: true,
      },
      {
        key: 'pendencias',
        title: 'Pendências',
        noPadding: true,
        component: <Pendencies />,
      },
      {
        key: 'arquivos',
        title: 'Arquivos',
        noPadding: true,
        component: <Files id={person?.id} />,
      },
      {
        key: 'resets',
        title: 'Resets',
        noPadding: true,
        component: <Resets />,
      },
    ];

    return tabsList;
  }, [person, loading, isTicketsLoading, opportunities]);

  return (
    <Tabs loading={loading} tabs={tabs} persistOnQuery={{ key: 'aux' }} testId="auxiliary-info" />
  );
}
