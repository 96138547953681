import React, { useCallback, useState } from 'react';
import { Alert, Flex } from '~/ui/components';
import {
  CopyableText,
  CopyableTextWrapper,
  CopyButton,
} from '~/screens/Clix/ContractDetails/Drawers/InvoiceDetails/InvoiceDetails.styled';
import { message } from 'antd';
import { useTracking } from '~/hooks/useTracking';
import { ampli } from '~/ampli';
import { useAwaitControl } from 'react-redux-await-control';
import { getPersonDetail } from '~/store/people/actions';

export type CopyableTextBoxProps = {
  textToCopy: string;
  successText?: string;
  errorText?: string;
  buttonText?: string;
  helpText?: string;
};

export function CopyableTextBox({
  textToCopy,
  successText,
  errorText,
  helpText,
  buttonText,
}: CopyableTextBoxProps) {
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const { handleTracking } = useTracking();
  const [copied, setCopied] = useState(false);
  const personDetails = getPersonDetailsControl.result();

  const handleCopyText = useCallback(() => {
    const clipBoard = navigator.clipboard;
    clipBoard.writeText(textToCopy).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      },
      () => {
        message.error(errorText || 'Não foi possível copiar o texto');
      },
    );

    const event = ampli.formalizacaoConcluiuFormalizacao({
      titulo_tela: 'PersonDetailsNew',
      titulo_aba: 'Oportunidade',
      cliente_person_id: personDetails.id,
      cliente_convenio: personDetails.agreementName,
      cliente_oportunidade: personDetails.isOpportunity ?? false,
    });

    handleTracking(() => event);
  }, [textToCopy]);

  return (
    <Flex gap={14} direction="column" align="stretch" width="100%">
      <Alert
        className="alert-copy"
        label={
          helpText ||
          'Copie o texto abaixo. Em seguida, você pode colar e enviar para o seu cliente.'
        }
        status="warning"
      />

      <CopyableTextWrapper>
        <CopyableText>{textToCopy}</CopyableText>
      </CopyableTextWrapper>

      <CopyButton copied={copied} onClick={handleCopyText}>
        {copied ? successText || 'Copiado' : buttonText || 'Copiar'}
      </CopyButton>
    </Flex>
  );
}
