import { createAsyncAction } from 'react-redux-await-control';
import { message } from 'antd';
import moment from 'moment';
import Masker from 'vanilla-masker';

import { telli, insurance } from '~/services';
import Hiscon from '~/typings/entities/Hiscon';
import { endpoint } from '~/store';
import { PersonRegistrationLog } from '~/typings/entities/person';
import { handleHisconErrors } from '~/common/utils/errorHandler';

message.config({});

export const getPersonDetail = createAsyncAction('GET_PERSON_DETAIL', {
  saveResult: true,
  initialValue: {},
  context: {
    request: ({ id }) => telli.get(`/admin/personcustom/people/${id}`),
  },
});

export const updatePersonInfo = createAsyncAction('UPDATE_PERSON_INFO', {
  initialValue: {},
  saveResult: true,
  context: {
    request: (payload) => {
      const data = payload || {};

      if (payload && payload.phoneNumber1) {
        data.phoneNumber1 = Masker.toPattern(payload.phoneNumber1, '99999999999');
      }

      if (payload && payload.extinctionDate) {
        data.extinctionDate = moment(payload.extinctionDate).utc().toDate();
      }

      return telli.post(`/admin/personcustom/people/${payload.id}/updateinfo`, data);
    },
    additionalMeta: {
      errorMessage: 'Erro ao atualizar informações',
    },
    onRequestSuccess: () => message.success('Informações atualizadas com sucesso!'),
  },
});

export const requestResetPerson = createAsyncAction('REQUEST_RESET_PERSON', {
  context: {
    request: ({ id, password, personResetReasonId, openResetReasonDescription }) =>
      telli.post(`/admin/personcustom/people/${id}/requestreset`, {
        id,
        password,
        personResetReasonId,
        openResetReasonDescription,
      }),
    additionalMeta: {
      errorMessage: 'Falha ao solicitar reset de pessoa.',
    },
    onRequestSuccess: () => message.success('Reset solicitado com sucesso!'),
  },
});

export const resetPerson = createAsyncAction('RESET_PERSON', {
  context: {
    request: ({ id, password }) =>
      telli.post(`/admin/personcustom/people/${id}/reset`, { id, password }),
    additionalMeta: {
      errorMessage: 'Falha ao resetar pessoa.',
    },
    onRequestSuccess: () => message.success('Cadastro resetado com sucesso!'),
  },
});

export const getResetReasons = createAsyncAction('FETCH_PERSON_REGISTER_RESET_REASONS', {
  saveResult: true,
  initialValue: [],
  context: {
    request: () => telli.get('/admin/personcustom/resetreasons'),
  },
});

export const getPersonResetHistory = createAsyncAction('GET_PERSON_RESET_HISTORY', {
  saveResult: true,
  initialValue: {},
  context: {
    request: ({ id }) => telli.get(`/admin/personcustom/people/${id}/resethistory`),
  },
});

export const uploadHiscon = createAsyncAction('UPLOAD_HISCON', {
  context: {
    request: ({ personId, file, version }) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('filetype', version === 'old' ? 'EXTRACT' : 'EXTRACT2');
      return telli.post(`/admin/personcustom/people/${personId}/uploadhiscon`, formData);
    },
    additionalMeta: {
      errorMessage: 'Falha ao Fazer Upload de Hiscon.',
    },
    onRequestSuccess: () => message.success('Upload de Hiscon concluído com sucesso.'),
    onRequestError: (err: any) =>
      handleHisconErrors(err, { defaultMessage: 'Erro no upload do Hiscon', duration: 5 }),
  },
});

export const uploadConcession = createAsyncAction('UPLOAD_CONCESSION', {
  context: {
    request: ({ personId, file }) => {
      const formData = new FormData();
      formData.append('file', file);
      return telli.post(`/admin/personcustom/people/${personId}/uploadconcession`, formData);
    },
    additionalMeta: {
      errorMessage: 'Falha ao Fazer Upload da carta de concessão.',
    },
    onRequestSuccess: () => message.success('Upload de carta de concessão concluído com sucesso.'),
  },
});

export const uploadMedicalCertificate = createAsyncAction('UPLOAD_MEDICAL_CERTIFICATE', {
  context: {
    request: ({ personId, medicalCertificate, medicalCertificateDate }) => {
      const formData = new FormData();

      formData.append('file', medicalCertificate);
      formData.append('medicalCertificateDate', medicalCertificateDate);

      return telli.post(
        `${endpoint}/admin/personcustom/people/${personId}/uploadmedicalcertificate`,
        formData,
      );
    },
    additionalMeta: {
      errorMessage: 'Falha ao fazer Upload de perícia médica.',
    },
    onRequestSuccess: () => message.success('Upload de perícia médica concluído com sucesso.'),
  },
});

export const listPeople = createAsyncAction('LIST_PEOPLE', {
  saveResult: true,
  initialValue: {
    entries: [],
    totalEntries: 0,
  },
  context: {
    request: (params: string) => telli.get(`/admin/personcustom/people`, { params }),
  },
});

export const listRegistrationStatus = createAsyncAction('LIST_REGISTRATION_STATUSES', {
  saveResult: true,
  initialValue: [],
  context: {
    request: () => telli.get('/admin/personcustom/registrationstatuses'),
  },
});

export const getPersonImage = createAsyncAction('GET_PERSON_IMAGE', {
  saveResult: true,
  context: {
    onRequestError: () => {},
    request: ({ id, type }) =>
      telli.get(`/admin/personcustom/people/${id}/image?type=${type}`, {
        responseType: 'blob',
      }),
    responseParser: (response, { type }) => ({
      type,
      exist: response.data.size !== 0,
      size: response.data.size,
      fileType: response.data.type,
      uri: URL.createObjectURL(new Blob([response.data])),
    }),
  },
});

export const getPersonImageUrl = createAsyncAction('GET_PERSON_IMAGE_URL', {
  saveResult: true,
  context: {
    onRequestError: () => {},
    request: ({ id, type }) => telli.get(`/admin/personcustom/people/${id}/urlimage?type=${type}`),
    responseParser: ({ data }) => data?.imageUrl,
  },
});

export const getPersonRegistryFiles = createAsyncAction('GET_PERSON_REGISTRY_FILES', {
  saveResult: true,
  context: {
    request: ({ id }) => telli.get(`/admin/personcustom/people/${id}/registryfiles`),
  },
});

export const updateHumanizePerson = createAsyncAction('UPDATE_HUMANIZE_PERSON', {
  saveResult: true,
  context: {
    request: ({ id, infoData }) =>
      telli.patch(`/admin/personcustom/personcomment/updatehumanizeperson/${id}`, infoData),
    onRequestSuccess: () => message.success('Flag atualizada com successo.'),
  },
});

export const updateHumanizePersonComment = createAsyncAction('UPDATE_HUMANIZE_PERSON_COMMENT', {
  saveResult: true,
  context: {
    request: ({ id, comment }) =>
      telli.patch(`/admin/personcustom/personcomment/updatehumanizeperson/${id}`, {
        typeOfInformation: 'INFORMATION',
        information: comment,
      }),
    onRequestSuccess: () => message.success('Comentário atualizado com sucesso.'),
  },
});

export const getContractsOfPerson = createAsyncAction('GET_CONTRACTS_OF_PERSON', {
  saveResult: true,
  initialValue: {
    entries: [],
    totalEntries: 0,
  },
  context: {
    request: ({ id }) => telli.get(`/admin/personcustom/people/${id}/contracts`),
  },
});

export const addCommentToPerson = createAsyncAction('ADD_PERSON_COMMENT', {
  context: {
    request: (payload) => telli.post('/admin/personcustom/personcomment', payload),
    additionalMeta: {
      errorMessage: 'Erro ao tentar adicionar comentário',
    },
    responseParser: (result, customPayload) => ({ ...result, ...customPayload }),
    onRequestSuccess: () => message.success('Comentário adicionado com sucesso'),
  },
});

export const updateCommentToPerson = createAsyncAction('UPDATE_PERSON_COMMENT', {
  context: {
    request: ({ id, ...data }) => telli.put(`/admin/personcustom/personcomment/${id}`, data),
    additionalMeta: {
      errorMessage: 'Erro ao tentar atualizar comentário',
    },
    responseParser: (result, customPayload) => ({ ...result, ...customPayload }),
    onRequestSuccess: () => message.success('Comentário atualizado com sucesso'),
  },
});

export const listPersonComments = createAsyncAction('LIST_PERSON_COMMENT', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ personId }) => telli.get(`/admin/personcustom/personcomment/${personId}/comments`),
    errorParser: () => [],
  },
});

export const getINSSOpportunity = createAsyncAction('GET_INSS_OPPORTUNITY', {
  saveResult: true,
  initialValue: { isOpportunity: false },
  context: {
    request: ({ personId }) => telli.get(`/admin/margin/${personId}/oportunity`),
    errorParser: () => false,
  },
});

export const getFGTSOpportunity = createAsyncAction('GET_FGTS_OPPORTUNITY', {
  saveResult: true,
  initialValue: { isOpportunity: false },
  context: {
    request: ({ personId }) => telli.get(`/admin/margin/fgts/${personId}/oportunity`),
    errorParser: () => false,
  },
});

export const getDatavalid = createAsyncAction('GET_DATAVALID', {
  saveResult: true,
  initialValue: {},
  context: {
    request: ({ personId, forceUpdate }) =>
      telli.get('/admin/personcustom/serprofacialbiometry', { params: { personId, forceUpdate } }),
    onRequestSuccess: (_, { forceUpdate }) =>
      forceUpdate && message.success('Solicitação de verificação realizada com sucesso!'),
    onRequestError: (_, { forceUpdate }) =>
      forceUpdate && message.error('Falha ao solicitar verificação'),
  },
});

export const getPersonPaycheckFile = createAsyncAction('GET_PERSON_PAYCHECKFILE', {
  saveResult: true,
  context: {
    request: ({ personId, paycheckId }) =>
      telli.get(`/admin/personcustom/people/${personId}/paycheckfile/${paycheckId}`, {
        responseType: 'blob',
      }),
    responseParser: (response) => URL.createObjectURL(response.data),
    onRequestError: () => message.error('Falha ao Baixar Arquivo.'),
  },
});

export const getPersonPaychecks = createAsyncAction('LIST_PERSON_PAYCHECKFILE', {
  saveResult: true,
  initialValue: { entries: [] },
  context: {
    request: ({ personId }) => telli.get(`/admin/personcustom/people/${personId}/paycheckfile`),
    responseParser: ({ data: { resource } }) => ({
      ...resource,
      entries: resource?.entries?.map((data) => new Hiscon(data)) || [],
    }),
  },
});

export const approvePersonRegistry = createAsyncAction('APPROVE_PERSON_REGISTRY', {
  context: {
    request: ({ id }) => telli.post(`/admin/personcustom/people/${id}/approveregistry`, {}),
  },
});

export const reprovePersonRegistry = createAsyncAction('REFUSE_PERSON_REGISTRY', {
  context: {
    request: ({ id }) => telli.post(`/admin/personcustom/people/${id}/refuseregistry`, {}),
  },
});

export const requestAgreementPassword = createAsyncAction('REQUEST_AGREEMENT_PASSWORD', {
  context: {
    request: (payload) =>
      telli.post(`/admin/personcustom/people/${payload.id}/requestagreementpassword`, payload),
    onRequestSuccess: () => message.success('Senha requisitada com sucesso!'),
    onRequestError: () => message.error('Falha ao requisitar senha'),
  },
});

export const getPersonRegistrationLogs = createAsyncAction('LIST_PERSON_REGISTRATION_LOG', {
  saveResult: true,
  initialValue: [],
  context: {
    request: (payload: { personId: string }) =>
      telli.get(`/admin/personcustom/personregistrationlogs/${payload.personId}/person`),
    responseParser: ({ data: { resource } }: { data: { resource: PersonRegistrationLog[] } }) =>
      resource
        .filter((log) => ['APPROVED', 'REFUSED'].includes(log?.registrationStatus))
        .sort(
          (logA, logB) =>
            moment(logB.operationDate, 'DD/MM/YYYY HH:mm:ss').valueOf() -
            moment(logA.operationDate, 'DD/MM/YYYY HH:mm:ss').valueOf(),
        ),
    errorParser: () => [],
  },
});

export const sendRevokeAgreementPasswordToken = createAsyncAction('SEND_AGREEMENT_PASSWORD_TOKEN', {
  saveResult: true,
  initialValue: null,
  context: {
    request: ({ personId }) =>
      telli.post(`/admin/personcustom/people/${personId}/requesttokenarevokegreementpassword`, {}),
    errorParser: () => [],
  },
});

export const validateRevokeAgreementPasswordToken = createAsyncAction(
  'VALIDATE_AGREEMENT_PASSWORD_TOKEN',
  {
    saveResult: true,
    initialValue: null,
    context: {
      request: ({ personId, token }) =>
        telli.post(`/admin/personcustom/people/${personId}/validatetokenarevokegreementpassword`, {
          token,
        }),
      errorParser: () => [],
    },
  },
);

export const revokeAgreementPassword = createAsyncAction('REVOKE_AGREEMENT_PASSWORD', {
  saveResult: true,
  initialValue: null,
  context: {
    request: ({ personId }) =>
      telli.get(`/admin/personcustom/revokeconsentterm`, { params: { personId } }),
    errorParser: () => [],
  },
});

export const getAgreementPasswordHistory = createAsyncAction('AGREEMENT_PASSWORD_HISTORY', {
  saveResult: true,
  initialValue: { content: [], last: true, totalPages: 0 },
  context: {
    request: ({ personId, page, size }) =>
      telli.get(`/admin/personcustom/people/${personId}/events`, {
        params: { page, size: size || 5 },
      }),
    responseParser: ({ data }) => data?.resource,
    errorParser: () => [],
  },
});

export const getJourneyHistory = createAsyncAction('GET_JOURNEY_HISTORY', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ personId, initDate, endDate, channel }) =>
      telli.get(`/admin/journeyhistory`, {
        params: {
          personId,
          channel,
          initDate,
          endDate,
        },
      }),
    responseParser: ({ data }) => data?.resource,
    onRequestError: () => {},
    errorParser: () => [],
  },
});

export const getLifeInsurance = createAsyncAction('GET_LIFE_INSURANCE', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ personId }) => insurance.get(`/insurance/life/proposal/customer/${personId}`),
    responseParser: ({ data }) => data,
    errorParser: () => [],
  },
});
