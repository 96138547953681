import React, { ReactElement, useRef, useState } from 'react';
import { domToClipboard, domToDownload } from '~/common/domToFile';

import { Button, Flex } from '~/ui/components';
import { ampli } from '~/ampli';
import { useTracking } from '~/hooks/useTracking';
import { useAwaitControl } from 'react-redux-await-control';
import { getPersonDetail } from '~/store/people/actions';
import { Container, ImageContainer } from './DownloadNodeModal.styled';

export type DownloadNodeModalProps = {
  children: ReactElement;
  fileName: string;
};

export function DownloadNodeModal({ children, fileName }: DownloadNodeModalProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isCopied, setIsCopied] = useState(false);
  const personControl = useAwaitControl(getPersonDetail);
  const personDetails = personControl.result();

  const { handleTracking } = useTracking();

  const handleCopy = async () => {
    const event = ampli.simulacaoConcluiuSimulacao({
      titulo_tela: 'PersonDetailsNew',
      titulo_aba: 'Oportunidade',
      cliente_person_id: personDetails.enrollmentId,
      cliente_convenio: personDetails.agreementName,
      cliente_oportunidade: personDetails.isOpportunity ?? false,
    });

    handleTracking(() => event);

    const success = await domToClipboard(containerRef?.current);
    setIsCopied(success);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleDownload = () => {
    const event = ampli.simulacaoConcluiuSimulacao({
      titulo_tela: 'PersonDetailsNew',
      titulo_aba: 'Oportunidade',
      cliente_person_id: personDetails.enrollmentId,
      cliente_convenio: personDetails.agreementName,
      cliente_oportunidade: personDetails.isOpportunity ?? false,
    });
    handleTracking(() => event);

    domToDownload(containerRef?.current, fileName);
  };

  return (
    <Container loading={isCopied}>
      <ImageContainer ref={containerRef}>{children}</ImageContainer>

      <Flex gap={8} direction="column" width="100%" ph={32} pb={16}>
        <Button
          fullWidth
          rounded
          customColor={isCopied ? 'positive.primary' : 'black'}
          icon={isCopied ? 'Check' : 'Paste'}
          onClick={handleCopy}
        >
          {isCopied ? 'Imagem copiada!' : 'Copiar imagem'}
        </Button>

        <Button
          rounded
          fullWidth
          color="black"
          variant="outline"
          icon="Download"
          onClick={handleDownload}
        >
          Baixar imagem
        </Button>
      </Flex>
    </Container>
  );
}
