import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-block: 24px;
`;

export const Case = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.brand.primary};
    transform: rotate(-90deg);
  }
`;

type SectionProps = {
  gap?: number;
};

export const Section = styled.div<SectionProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap ?? 24}px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  a {
    color: ${({ theme }) => theme.colors.brand.primary};
    font-weight: 600;
    text-decoration: underline;
  }
`;

type BoxProps = {
  open: boolean;
};

export const Box = styled.div<BoxProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${({ open }) =>
    open &&
    css`
      svg {
        transform: rotate(90deg);
      }
    `}
`;
