import React, { Fragment, useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import moment from 'moment';

import { InfoGroup, Tag, Typography } from '~/ui/components';
import { CaretRight } from '~/ui/assets/icons';
import { Money } from '~/common';
import InsuranceStatus, { InsuranceStatusColor } from '~/constants/Insurance';
import { LifeInsuranceType } from '~/typings/entities/Insurance';
import { Box, Grid, Section, Case, Wrapper } from './LifeInsurance.styled';

export type LifeInsuranceProps = {
  insurance: LifeInsuranceType;
};

export function LifeInsurance({ insurance }: LifeInsuranceProps) {
  const [openedFields, setOpenedFields] = useState([]);

  const theme = useTheme();

  const handleOnClick = useCallback(
    (name: string) => {
      const newOpenedFields = [...openedFields];

      if (newOpenedFields.includes(name)) {
        newOpenedFields.splice(newOpenedFields.indexOf(name), 1);
      } else {
        newOpenedFields.push(name);
      }

      setOpenedFields(newOpenedFields);
    },
    [openedFields],
  );

  const withMax = ['Assistência funeral individual', 'Diárias de internação hospitalar'];

  const renderWeight = (name) => {
    if (name === 'Vida') {
      return 700;
    }
    return 400;
  };

  const renderDetails = (details) => {
    const newDetails = [details];
    const updatedDetails = newDetails[0]
      .split('\n')
      .map((str) => str.trim())
      .filter(Boolean);

    return (
      <Section gap={12}>
        {updatedDetails.map((detail, index) => (
          <Typography type="caption" key={index}>
            {detail}
          </Typography>
        ))}
      </Section>
    );
  };

  const status = InsuranceStatus[insurance?.status];
  const textColor = theme.colors?.[InsuranceStatusColor[status]]?.primaryAlt;
  const bgColor = theme.colors?.[InsuranceStatusColor[status]]?.secondary;

  return (
    <Wrapper>
      <Case>
        <Typography type="caption">Status do contrato</Typography>
        <Tag textColor={textColor} bgColor={bgColor}>
          {status}
        </Tag>
      </Case>
      <Section>
        <Typography type="bodyLarge" weight={700}>
          Detalhes do contrato
        </Typography>
        <Grid>
          <InfoGroup label="Tipo" value="Seguro de Vida" />
          <InfoGroup
            label="Início da contratação"
            value={moment(insurance?.startDate).format('DD/MM/YYYY')}
          />

          <InfoGroup label="Número da apólice" value={insurance?.policyNumber} />
          <InfoGroup
            label="Vigência Apólice Individual"
            value={moment(insurance?.endDate).format('DD/MM/YYYY')}
          />
          <InfoGroup label="Valor do Seguro" value={Money(insurance?.plan?.value)} />
        </Grid>
      </Section>
      <Section gap={16}>
        <Typography type="bodyLarge">{insurance?.plan?.name}</Typography>
        <Typography type="bodyMedium" weight={700}>
          Coberturas
        </Typography>
        <Section gap={20}>
          {insurance &&
            insurance?.coverages?.map((coverage, index) => (
              <Fragment key={index}>
                <Box
                  onClick={() => handleOnClick(coverage?.name)}
                  open={openedFields.includes(coverage?.name)}
                >
                  <Typography type="paragraphSmall" weight={600}>
                    {coverage?.description}
                  </Typography>
                  <Case>
                    <Typography type="caption" weight={renderWeight(coverage?.name)}>
                      {withMax.includes(coverage?.name) && 'até '}
                      {Money(coverage?.capital)}
                    </Typography>
                    <CaretRight width={12} height={12} />
                  </Case>
                </Box>
                {openedFields.includes(coverage?.name) && renderDetails(coverage?.detail)}
              </Fragment>
            ))}
        </Section>
      </Section>
    </Wrapper>
  );
}
