import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 320px;
  justify-content: space-between;
  width: 320px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border: 0.5px solid ${({ theme }) => theme.colors.element.disabledBackground};
  border-radius: 8px;
  margin-bottom: 8px;

  :hover {
    border-width: 1px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
`;

interface ContentProps {
  row?: boolean;
  width?: number;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  gap: 4px;

  ${({ row }) =>
    row &&
    css`
      justify-content: space-between;
      align-items: start;
    `}

  ${({ width }) =>
    width &&
    css`
      min-width: ${width}px;
    `}
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
